import { Outlet, useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { initializeUser } from "store/actions/user";
import { AUTH_EXCEPT_ROUTES } from "utils/constants";

const InitializerLayout = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation();

  useEffect(() => {
    if (!AUTH_EXCEPT_ROUTES.includes(path.pathname)) {
      const init = async () => {
        await dispatch(initializeUser());
        if (path.pathname === "/") navigate("hedges/latest");
      };
      init();
    }
  }, []);

  useEffect(() => {
    if (user?.isLoggedIn) {
      if (path.pathname === "/") navigate("hedges/latest");
    } else if (user?.isError) {
      navigate("/login", { replace: true });
    }
  }, [user]);

  return <Outlet />;
};

export default InitializerLayout;
