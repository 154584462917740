import { client, unAuthClient } from 'utils/api'
import { AUTH_STORAGE_KEY } from 'utils/constants'
import { getItemLocal } from 'utils/localStorage'
import { USER_LOADING, USER_LOGIN, USER_ERROR, USER_LOGOUT, USER_SET } from './types'

export function loginUser(formData, router) {
  return async (dispatch) => {
    dispatch({ type: USER_LOADING, isLoading: true })
    try {
      const response = await unAuthClient.post('/users/token/', formData, { skipAuthRefresh: true })
      if (response.status === 200) {
        dispatch({
          type: USER_LOGIN,
          payload: response.data,
        })

        const getUser = client.get('/users/me/')
        try {
          const result = await Promise.all([getUser])
          const user = result[0]
          dispatch({
            type: USER_SET,
            payload: user.data,
          })
        } catch (err) {
          window.location.href = "/login"
        }
      }
    } catch (error) {
      return dispatch({
        type: USER_ERROR,
        error,
      })
    }
  }
}

export function initializeUser() {
  return async (dispatch) => {
    const localUser = getItemLocal(AUTH_STORAGE_KEY)
    if (localUser !== null) {
      dispatch({ type: USER_LOGIN, payload: localUser })

      try {
        const getUser = client.get('/users/me/')
        try {
          const result = await Promise.all([getUser])
          const user = result[0]
          dispatch({
            type: USER_SET,
            payload: user.data,
          })
        } catch (err) {
          // handle error
        }
      } catch (error) {
        return dispatch({
          type: USER_ERROR,
          error,
        })
      }
    } else {
      dispatch({ type: USER_LOADING, isLoading: false })
    }
  }
}

export function setUser(payload) {
  return {
    type: USER_SET,
    payload,
  }
}

export function logoutUser(payload) {
  return async (dispatch) => {
    if (payload) {
      await client.post('/users/logout/', {
        refresh: payload.refresh,
      })
    }
    dispatch({
      type: USER_LOGOUT,
    })
  }
}

export function activateUser({
  name,
  surname,
  phone,
  newPassword,
  newPasswordRepeat,
  uuid,
  token,
}) {
  return async (dispatch) => {
    dispatch({ type: USER_LOADING, isLoading: true })
    try {
      const data = {
        first_name: name,
        last_name: surname,
        new_password_1: newPassword,
        new_password_2: newPasswordRepeat,
        uuid,
        token,
      }
      if (phone) data.phone_number = phone
      await unAuthClient.post('/users/activation/', data)
      dispatch({ type: USER_LOADING, isLoading: false })
    } catch (error) {
      return dispatch({
        type: USER_ERROR,
        error,
      })
    }
  }
}

export function resetUsersPassword({ newPassword, newPasswordRepeat, uuid, token }) {
  return async (dispatch) => {
    dispatch({ type: USER_LOADING, isLoading: true })
    try {
      await unAuthClient.post('/users/password-reset-confirm/', {
        new_password_1: newPassword,
        new_password_2: newPasswordRepeat,
        uuid,
        token,
      })
      dispatch({ type: USER_LOADING, isLoading: false })
    } catch (error) {
      return dispatch({
        type: USER_ERROR,
        error,
      })
    }
  }
}
