import { getRuns } from "store/actions/runs";
import getDropdownOptions from "utils/getDropdownOptions";
import AsyncPaginatedDropdown from "../async-paginate";

export default function RunsDropdown({
  name,
  defaultValue,
  onChange,
  placeholder,
  isClearable = false,
}) {
  const runFetcher = async (params) => {
    const res = await getRuns({
      ...params,
      // Added for future cases - requires filterset_query to be added to view run__status
      //run__status__gte: RUN_STATUS.COMPLETED,
    });
    res.results = getDropdownOptions(res.results, "name");
    return res;
  };

  return (
    <AsyncPaginatedDropdown
      fetcher={runFetcher}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
      isClearable
    />
  );
}
