import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter } from 'react-table'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { PageWithText } from '../pagination'

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} className="form-checkbox h-4 w-4" />
})

const Datatable = ({
  columns,
  data,
  noPagination,
  canNextPage,
  canPrevPage,
  nextPage,
  prevPage,
  onSizeChange,
  onSort,
  controledPageSize,
  pageCount,
  pageIndex,
}) => {
  const dataMemo = useMemo(() => data, [data])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    setPageSize,
    gotoPage,
    state: { pageSize, sortBy },
 
  } = useTable(
    {
      
      manualPagination: true,
      manualSortBy: true,
      columns,
      data: dataMemo,
      initialState: { pageIndex: 0, hiddenColumns: ['id'] },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // comment out for checkboxes
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </>
    //       ),
    //     },
    //     ...columns,
    //   ])
    // }
  )
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (!isFirstRender.current) {
      if (!onSort) return
      if (sortBy.length > 0) {
        // creating a sort string that django understands
        const sortStr = `${sortBy[0].desc ? '-' : ""}${sortBy[0].id.replace(/\./g, '__')}`
        onSort(sortStr)
      } else {
        onSort(null)
      }
    } else {
      isFirstRender.current = false
    }
  }, [sortBy])
  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex flex-row items-center justify-start">
                    <span>{column.render('Header')}</span>
                    {/* Add a sort direction indicator */}
                    <span className="ml-auto">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FiChevronDown className="stroke-current text-2xs" />
                        ) : (
                          <FiChevronUp className="stroke-current text-2xs" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                {/* <td>test</td> */}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="flex flex-row items-center justify-between my-4">
        <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
          {/* {pageIndex !== 0 && <PageWithText onClick={() => gotoPage(0)}>First</PageWithText>} */}
          {canPrevPage && <PageWithText onClick={() => prevPage()}>Previous</PageWithText>}
          {canNextPage && (
            <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </PageWithText>
          )}
          {/* {pageIndex !== pageCount - 1 && (
            <PageWithText onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              Last
            </PageWithText>
          )} */}
        </div>

        {noPagination || (
          <>
            <span>
              Page{' '}
              <b>
                {pageIndex} of {pageCount}
              </b>{' '}
            </span>
            <select
              className="form-select text-sm bg-white dark:bg-gray-800 dark:border-gray-800 outline-none shadow-none focus:shadow-none"
              value={controledPageSize || pageSize}
              onChange={(e) => {
                onSizeChange?.(Number(e.target.value))
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 25, 50, 100].map((ps) => (
                <option key={ps} value={ps}>
                  Show {ps}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
      {/* <pre>
        {JSON.stringify(
          data.filter((item, index) =>
            Object.keys(selectedRowIds)
              .map((i) => parseInt(i, 10))
              .includes(index)
          ),
          null,
          2
        )}
      </pre> */}
    </>
  )
}

export default Datatable
