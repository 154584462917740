const List = ({ items, buttonClickHandler }) => {
  return (
    <div className="flex flex-row flex-wrap py-2 max-h-52 overflow-y-scroll rounded bg-gray-800">
      {items
        ? items.map((item, i) => (
            <div key={i} className="w-full">
              <button
                className="flex w-full items-center justify-start dropdown-item px-3 py-2 hover:bg-gray-700"
                onClick={() => buttonClickHandler(item)}
                type="button"
                key={i}
              >
                <div className="text-sm whitespace-nowrap">{item.text}</div>
              </button>
            </div>
          ))
        : null}
    </div>
  )
}

export default List
