import React, { useState } from 'react'
import { useLocation, Link, NavLink } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'

const Item = ({ url, icon, title, badge, items }) => {
  const [hidden, setHidden] = useState(true)
  const resolvedPath = useLocation()
  const pathname = resolvedPath.pathname
  let active = pathname === url
  if (pathname === '/' && url === '/dashboard') {
    active = true
  }
  if (pathname === '/' && url !== '/dashboard') {
    active = false
  }
  if (items.length === 0) {
    return (
      <NavLink to={url} className={({ isActive }) => `left-sidebar-item ${isActive ? 'active' : ''}`}>
        {icon}
        <span className="title">{title}</span>
        {badge && (
          <span className={`badge badge-circle badge-sm ${badge.color}`}>{badge.text}</span>
        )}
      </NavLink>
    )
  }
  return (
    <button
      type="button"
      onClick={() => setHidden(!hidden)}
      className={`left-sidebar-item ${active ? 'active' : ''} ${
        hidden ? 'hidden-sibling' : 'open-sibling'
      }`}
    >
      {icon}
      <span className="title">{title}</span>
      {badge && <span className={`badge badge-circle badge-sm ${badge.color}`}>{badge.text}</span>}
      <FiChevronRight className="ml-auto arrow" />
    </button>
  )
}

export default Item
