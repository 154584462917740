import PropTypes from 'prop-types'

const TextInput = (props) => {
  const { error } = props
  return (
    <input className={`form-input ${error ? 'form-input-invalid' : ''}`} type="text" {...props} />
  )
}

TextInput.propTypes = {
  error: PropTypes.string,
}

TextInput.defaultProps = {
  error: '',
}

export default TextInput
