import { Navigate } from "react-router-dom"
import { client } from "utils/api"
import { getItemLocal } from "utils/localStorage"

export function startTask(formData) {
  return async (dispatch) => {
    try {
      const response = await client.post(`/runs/`, formData)
      return response.data
    } catch (err) {
      const error = {
        status: err.response,
      }
    }
  }
}

export function getRun(id) {
  return async (dispatch) => {
    try {
      const response = await client.get(`/runs/${id}/`)
      return response.data
    } catch (error) {
    }
  }
}
