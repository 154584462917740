import React from 'react'
import Title from 'components/left-sidebar/title'
import Item from 'components/left-sidebar/item'
import Logo from 'components/left-sidebar/logo'
import { BiBuildings } from 'react-icons/bi'

const LeftSidebar = () => {
  return (
    <div className="left-sidebar left-sidebar-1">
      <Logo />
      {sidebarItems.map((menu, i) => (
        <React.Fragment key={i}>
          <Title>{menu.title}</Title>
          <ul>
            {menu.items.map((l0, a) => (
              <li key={a} className="l0">
                <Item {...l0} />
                <ul>
                  {l0.items.map((l1, b) => (
                    <li key={b} className="l1">
                      <Item {...l1} />
                      <ul>
                        {l1.items.map((l2, c) => (
                          <li key={c} className="l2">
                            <Item {...l2} />
                            <ul>
                              {l2.items.map((l3, d) => (
                                <li key={d} className="l3">
                                  <Item {...l3} />
                                  <ul>
                                    {l3.items.map((l4, e) => (
                                      <li key={e} className="l4">
                                        <Item {...l4} />
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}

const sidebarItems = [
  {
    items: [
      {
        url: '/management/companies',
        icon: <BiBuildings size={20} />,
        title: 'Companies',
        items: [],
      },
      // {
      //   url: '/management/users',
      //   icon: <FiUsers size={20} />,
      //   title: 'Users',
      //   items: [],
      // },
    ],
  },
]

export default LeftSidebar
