export const Button = ({
  children,
  secondary,
  flat,
  onClick,
  large,
  small,
  disabled,
  className,
  type,
  rounded,
  wFull,
  red,
  yellow,
}) => {
  const size = large ? 'btn-lg' : small ? 'btn-sm' : 'btn-default'
  const btnClassName = secondary
    ? `btn-outlined bg-transparent ${
        red ? 'text-red-500 hover:text-red-700 border-red-500 hover:border-red-700'
          : yellow ? 'text-yellow-500 hover:text-yellow-700 border-yellow-500 hover:border-yellow-700'
          : 'text-blue-500 hover:text-blue-700 border-blue-500 hover:border-blue-700'
      }`
    : flat
    ? `bg-transparent ${
        red ? 'hover:bg-red-50 text-red-500 hover:text-red-600' 
          : yellow ? 'hover:bg-yellow-50 text-yellow-500 hover:text-yellow-600' 
          : 'hover:bg-blue-50 text-blue-500 hover:text-blue-600'
      }`
    : `bg-blue-500 ${red ? 'hover:bg-red-600' : yellow ? 'hover:bg-yellow-600' : 'hover:bg-blue-600'} text-white`

  return (
    <button
      className={`btn focus:ring-2 ${red ? 'ring-red-100' : ''}
        ${size} ${btnClassName} ${className} 
        ${rounded ? 'btn-rounded' : ''} ${wFull ? 'w-full' : ''} 
      `}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  )
}
