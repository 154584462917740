import { connect } from "react-redux"
import AuthenticatedLayout from "../auth/authenticated"
import LeftSidebar from "components/hedge/left-sidebar"
import { Outlet } from "react-router-dom"
import CleanNavbar from "components/navbar-main/clean-navbar"

const HedgeLayout = () => {
  return (
    <AuthenticatedLayout>
      <div
        data-background="light"
        data-navbar="light"
        data-left-sidebar="dark"
        className="font-sans antialiased text-sm disable-scrollbars flex flex-no-wrap"
      >
        <LeftSidebar />
        <div className="container mx-auto pb-10 ml-64 px-6">
          <CleanNavbar hideLogo />
          <div className="w-full p-4"><Outlet /></div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default connect(({ user }) => ({ user }))(HedgeLayout)