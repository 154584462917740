import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { clearInterval, setInterval } from "requestanimationframe-timer";

import { useParams } from "react-router";
import { getRun } from "store/actions/action-items";
import { RUN_STATUS } from "utils/constants/runs";
import { Button } from "components/buttons";
import RunStatus from "components/runs/run-status";
import { HiExternalLink } from "react-icons/hi";
import Datatable from "components/datatable";
import { humanize, formatNumber } from "utils/text";
import { client } from "utils/api";
import saveExcelFile from "utils/saveExcelFile";
import saveDocFile from "utils/saveDocFile";

const retrievalFields = [
  "loan_portfolio_notional_total",
  "loan_portfolio_mtm_r_delta",
  "loan_portfolio_mtm_delta_p100",
  "loan_portfolio_mtm_delta_n100",
  "deriv_portfolio_mtm_r_delta",
  "deriv_portfolio_mtm_delta_p100",
  "deriv_portfolio_mtm_delta_n100",
  "retrospective_effectiveness",
  "prospective_effectiveness_p100",
  "prospective_effectiveness_n100",
];

const RunDetail = ({ user }) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [timerId, setTimerId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [run, setRun] = useState();
  const [tableData, setTableData] = useState({
    columns: {},
    data: [],
  });
  const [allLogs, setAllLogs] = useState([]);
  const [isStopRun, setIsIsStopRun] = useState(false);
  const [isExportMatchResult, setIsExportMatchResult] = useState(false);
  const [isExportAmortizationSchedule, setIsAmortizationSchedule] =
    useState(false);
  const [isExportAccountingEntry, setIsExportAccountingEntry] = useState(false);
  const [isExportDesignationDocument, setIsExportDesignationDocument] =
    useState(false);
  const [isExportEffectivenessDocument, setIsExportEffectivenessDocument] =
    useState(false);

  const [isExportOutputJson, setIsExportOutputJson] = useState(false);

  const runTask = useCallback(async () => {
    const res = await dispatch(getRun(params.runId));
    setAllLogs([...allLogs, res.logs]);
    setRun(res);
  }, [timerId]);

  useEffect(() => {
    if (!timerId) {
      dispatch(getRun(params.runId)).then((res) => {
        setLoading(false);
        setRun(res);
        if (res.status < RUN_STATUS.COMPLETED) {
          const id = setInterval(runTask, 5000);
          setTimerId(id);
        }
        setAllLogs([...allLogs, res.logs]);
      });
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
        setTimerId(null);
      }
    };
  }, []);

  useEffect(() => {
    if (run?.status >= RUN_STATUS.COMPLETED) {
      clearInterval(timerId);
    }
  }, [run, timerId]);

  useEffect(() => {
    if (run?.output) {
      const data = {
        columns: [
          {
            Header: "",
            accessor: "label",
          },
          {
            Header: "",
            accessor: "value",
          },
        ],
        data: Object.keys(run.output)
          .filter((key) => retrievalFields.includes(key))
          .map((item) => ({
            label: humanize(item),
            value: formatNumber(run.output[item]),
          })),
      };
      setTableData(data);
    }
  }, [run]);

  return (
    <>
      {loading ? (
        <p>Loading Run details...</p>
      ) : (
        <>
          <div className="flex flex-col">
            <h2 className="flex">
              <span>Run: {run?.name}</span>
              <RunStatus status={run?.status} />
              {run?.status === RUN_STATUS.IN_PROGRESS && (
                <div>
                  <Button
                    large="true"
                    red="true"
                    className="ml-4 bg-red-500"
                    onClick={() => {
                      setIsIsStopRun(true);
                      client
                        .get(`/runs/stop/?run_id=${params.runId}`)
                        .then((res) => {
                          window.location.reload();
                        })
                        .catch(() => {
                          setIsIsStopRun(false);
                        });
                    }}
                    rounded
                    disabled={isStopRun}
                  >
                    {isStopRun ? "PLEASE WAIT..." : "Stop Run"}
                  </Button>
                </div>
              )}
              {run?.status === RUN_STATUS.COMPLETED && (
                <div>
                  <Button
                    large="true"
                    yellow="true"
                    className="ml-4 bg-yellow-500"
                    onClick={() => {
                      setIsIsStopRun(true);
                      client
                        .get(`/runs/set-official/?run_id=${params.runId}`)
                        .then((res) => {
                          window.location.reload();
                        })
                        .catch(() => {
                          setIsIsStopRun(false);
                        });
                    }}
                    rounded
                    disabled={isStopRun}
                  >
                    {isStopRun ? "PLEASE WAIT..." : "Set Official Run"}
                  </Button>
                </div>
              )}
            </h2>
            <div className="flex mt-4">
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsExportMatchResult(true);
                    client
                      .get(
                        `/export/match-result-for-run-detail/?run_id=${params.runId}`,
                        { responseType: "blob" }
                      )
                      .then((res) => {
                        saveExcelFile(
                          res.data,
                          `${params.runId}-match_result_for_run_detail.xlsx`
                        );
                        setIsExportMatchResult(false);
                      })
                      .catch(() => {
                        setIsExportMatchResult(false);
                      });
                  }}
                  rounded
                  disabled={isExportMatchResult}
                >
                  {isExportMatchResult
                    ? "PLEASE WAIT..."
                    : "Download Match Result"}
                </Button>
              </div>
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsAmortizationSchedule(true);
                    client
                      .get(
                        `/export/amortization-schedule-for-run-detail/?run_id=${params.runId}`,
                        { responseType: "blob" }
                      )
                      .then((res) => {
                        saveExcelFile(
                          res.data,
                          `${params.runId}-amortization_schedule_for_run_detail.xlsx`
                        );
                        setIsAmortizationSchedule(false);
                      })
                      .catch(() => {
                        setIsAmortizationSchedule(false);
                      });
                  }}
                  rounded
                  disabled={isExportAmortizationSchedule}
                >
                  {isExportAmortizationSchedule
                    ? "PLEASE WAIT..."
                    : "Download Amortization Schedule"}
                </Button>
              </div>
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsExportAccountingEntry(true);
                    client
                      .get(
                        `/export/accounting-entry-for-run-detail/?run_id=${params.runId}`,
                        { responseType: "blob" }
                      )
                      .then((res) => {
                        saveExcelFile(
                          res.data,
                          `${params.runId}-accounting_entry_for_run_detail.xlsx`
                        );
                        setIsExportAccountingEntry(false);
                      })
                      .catch(() => {
                        setIsExportAccountingEntry(false);
                      });
                  }}
                  rounded
                  disabled={isExportAccountingEntry}
                >
                  {isExportAccountingEntry
                    ? "PLEASE WAIT..."
                    : "Download Accounting Entry"}
                </Button>
              </div>
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsExportDesignationDocument(true);
                    client
                      .get(
                        `/export/designation-document/?run_id=${params.runId}`,
                        { responseType: "blob" }
                      )
                      .then((res) => {
                        saveDocFile(
                          res.data,
                          `${params.runId}-designation-document.docx`
                        );
                        setIsExportDesignationDocument(false);
                      })
                      .catch(() => {
                        setIsExportDesignationDocument(false);
                      });
                  }}
                  rounded
                  disabled={isExportDesignationDocument}
                >
                  {isExportDesignationDocument
                    ? "PLEASE WAIT..."
                    : "Download Designation Document"}
                </Button>
              </div>
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsExportEffectivenessDocument(true);
                    client
                      .get(
                        `/export/effectiveness-document/?run_id=${params.runId}`,
                        { responseType: "blob" }
                      )
                      .then((res) => {
                        saveDocFile(
                          res.data,
                          `${params.runId}-effectiveness-document.docx`
                        );
                        setIsExportEffectivenessDocument(false);
                      })
                      .catch(() => {
                        setIsExportEffectivenessDocument(false);
                      });
                  }}
                  rounded
                  disabled={isExportEffectivenessDocument}
                >
                  {isExportEffectivenessDocument
                    ? "PLEASE WAIT..."
                    : "Download Effectiveness Document"}
                </Button>
              </div>
              <div>
                <Button
                  className="mr-4"
                  onClick={() => {
                    setIsExportOutputJson(true);
                    client
                      .get(`/export/output/?run_id=${params.runId}`, {
                        responseType: "blob",
                      })
                      .then((res) => {
                        saveDocFile(res.data, `${params.runId}-output.json`);
                        setIsExportOutputJson(false);
                      })
                      .catch(() => {
                        setIsExportOutputJson(false);
                      });
                  }}
                  rounded
                  disabled={isExportOutputJson}
                >
                  {isExportOutputJson
                    ? "PLEASE WAIT..."
                    : "Download Output Json"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-2/5">
              <div className="">
                <h4 className="text-gray-500">{"Details"}</h4>

                <p className="text-base mt-2">
                  <span>Ran by: </span>
                  {run?.run_by.first_name} {run?.run_by.last_name}
                </p>
                {run?.status >= RUN_STATUS.COMPLETED && (
                  <p className="text-base mt-2">
                    <span>Official Run: </span>
                    {run.is_official ? "Yes" : "No"}
                  </p>
                )}
                {run?.status >= RUN_STATUS.COMPLETED &&
                  tableData.columns.length > 0 &&
                  tableData.data.length > 0 && (
                    <Datatable
                      columns={tableData.columns}
                      data={tableData.data}
                      noPagination
                    />
                  )}
              </div>
            </div>
            <div className="w-3/5">
              <div className="my-4">
                <h4 className="text-gray-500 flex">
                  {"Logs"}{" "}
                  {run?.status === RUN_STATUS.COMPLETED && (
                    <Button
                      secondary
                      rounded
                      small
                      onClick={() => navigate("/hedges/data-tables")}
                      className="ml-4 flex justify-center items-center"
                    >
                      Preview Data <HiExternalLink className="ml-1" />
                    </Button>
                  )}
                </h4>
                <div className="mt-4 bg-gray-700 text-white rounded-md p-4">
                  {allLogs[0]
                    ? Object.keys(allLogs[0]).map((item) => (
                        <div key={item} className="font-mono">
                          {item} - {allLogs[0][item]}
                        </div>
                      ))
                    : null}
                  {run?.status === RUN_STATUS.NEW ? (
                    <span className="font-mono italic">Waiting...</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(({ user }) => ({
  user,
}))(RunDetail);
