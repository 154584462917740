import {
  USER_LOADING,
  USER_LOGIN,
  USER_ERROR,
  USER_LOGOUT,
  USER_SET,
} from 'store/actions/user/types'
import { AUTH_STORAGE_KEY } from 'utils/constants'
import { removeItemLocal, setItemLocal } from 'utils/localStorage'

const initialState = { isLoggedIn: false, isLoading: true, isError: false }

export default function user(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case USER_LOADING:
      return { ...state, isLoading: action.isLoading, isError: false, error: {} }
    case USER_LOGIN:
      setItemLocal(AUTH_STORAGE_KEY, payload)
      return { isLoggedIn: true, ...payload }
    case USER_ERROR:
      return { ...state, isError: true, isLoading: false, error: action.error }
    case USER_SET:
      return { ...state, isLoading: false, user: payload }
    case USER_LOGOUT:
      removeItemLocal(AUTH_STORAGE_KEY)
      return { ...initialState, isLoading: false }
    default:
      return state
  }
}
