export const RUN_STATUS_TYPE = {
    1: 'New',
    2: 'In Progress',
    3: 'Completed',
    4: 'Exception',
    5: 'Error',
    6: 'Stopped'
  }
  
  export const RUN_STATUS = {
    "NEW": 1,
    "IN_PROGRESS": 2,
    "COMPLETED": 3,
    "EXCEPTION": 4,
    "ERROR": 5,
    "STOPPED": 6
  }
  