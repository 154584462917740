import {
  FaWallet,
  FaFire,
  FaCalendarAlt,
} from "react-icons/fa";
import { connect } from "react-redux";
import BlueBox from "components/hedge/box/blue";
import YellowBox from "components/hedge/box/yellow";
import SectionTitle from "components/section-title";
import GreenBox from "components/hedge/box/green";
import Loader from "components/loader";
import Datatable from "components/datatable";
import Widget from "components/widget";
import { formatNumber } from "utils/text";
import RedBox from "components/hedge/box/red";

const HedgingInstrumentPage = ({ run }) => {
  if (run.runs.isLoading)
    return (
      <>
        <Loader />
      </>
    );

  const columns = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Derivative Notionals",
      accessor: "value",
    },
  ];

  var data = run.runs.selected?.output?.hedge_to_ui;

  const hedgingInstrumentDataTable = data.hedging_instrument.data_table.Year.map((date, index) => ({
    date: date,
    value: formatNumber(data.hedging_instrument.data_table["Derivative Notionals"][index])
  }))

  return (
    <>
      <SectionTitle subtitle="Hedging Instrument" />
      <div className="flex gap-4 mb-4">
        <GreenBox
          bgColor="green-400"
          text="TRY Notional Hedging Instrument"
          value={formatNumber(data.hedging_instrument.boxes["TRY Notional"])}
          Icon={FaCalendarAlt}
        />
        <BlueBox
          bgColor="blue-400"
          text="USD Notional Hedging Instrument"
          value={formatNumber(data.hedging_instrument.boxes["USD Notional"])}
          unit=""
          Icon={FaWallet}
        />
        <YellowBox
          bgColor="yellow-400"
          text="MtM of Hedging Instrument"
          value={formatNumber(data.hedging_instrument.boxes["Mark-to-Market"])}
          unit=""
          Icon={FaFire}
        />
        <RedBox
          bgColor="yellow-400"
          text="Delta MtM of Hedging Instrument"
          value={formatNumber(data.hedging_instrument.boxes["MtM Delta"])}
          unit=""
          Icon={FaFire}
        />

      </div>
      <Widget>
        <Datatable columns={columns} data={hedgingInstrumentDataTable} />
      </Widget>
    </>
  );
};

export default connect(({ run }) => ({ run }))(HedgingInstrumentPage);
