import { useState } from "react"
import { HiChevronDown, HiChevronUp } from "react-icons/hi"

const TextCell = ({ row }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (row.original.exceptions) {
    return (
      <div className="accordion">
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div>{isExpanded ? <HiChevronUp className="m-1 ml-0 float-left" /> : <HiChevronDown className="m-1 ml-0 float-left" />} <div style={{width: 500}} className={`max-h-20 overflow-y-scroll truncate font-mono`}>{!isExpanded ? row.original.exceptions : ""}</div></div>
          </div>
          {isExpanded && <div onClick={() => setIsExpanded(!isExpanded)} className="accordion-content font-mono"> {row.original.exceptions}</div>}
        </div>
      </div>
    )
  }
}

export default TextCell
