export default function useQuery(key = null) {
    const qs = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(qs.entries())
    const exists = key && qs.has(key) && qs.get(key) !== null
    const value = exists ? qs.get(key) : null
    // if there is no key specified, return all params
    if (!key)
        return [params]
    // if specified, return value, if key exists, all params
    return [value, exists, params]
}