import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import AccountLinks from "./account-links"

const Dropdown = ({ user }) => {
  const [hidden, setHidden] = useState(true)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    setHidden((hidden) => !hidden)
  }

  return (
    <div className="">
      <button
        type="button"
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 rounded-full ml-2"
      >
        <span className="pt-6">
        <span className="">{user?.user?.first_name} {user?.user?.last_name} ({user?.user?.email})</span>
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`dropdown absolute top-0 right-0 mt-10 ${
          hidden ? "" : "open"
        }`}
      >
        <div className="dropdown-content w-48 bottom-end">
          <AccountLinks />
        </div>
      </div>
    </div>
  )
}

export default connect(({ user }) => ({ user }))(Dropdown)
