import PropTypes from 'prop-types'

const RadioButton = ({ item, onClick, name, value }) => {
  return (
    <div className="inline-flex items-center space-x-2">
      <input
        className="form-radio text-blue-500 h-4 w-4"
        type="radio"
        name={name}
        onClick={() => onClick(item.value)}
        checked={value === item.value}
        id={item.label}
      />
      <label htmlFor={item.label}>{item.label}</label>
    </div>
  )
}

RadioButton.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default RadioButton
