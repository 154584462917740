import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import { PageWithText } from "../pagination"
import { getAmortizationScheduleData } from "store/actions/datatables"
import Loader from "components/loader"
import RunsDropdown from "components/async-dropdown/runs"
import { connect } from 'react-redux'
import { formatNumber } from "utils/text";

const AmortizationScheduleDatatable = ({ run }) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [canPrevPage, setCanPrevPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(false)
  const [filters, setFilters] = useState({ run__id: run.runs.selected.id })

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const res = await getAmortizationScheduleData({
        page,
        page_size: pageSize,
        ...filters,
      })
      const data = res.data
      setData(data)
      setCanNextPage(data.next)
      setCanPrevPage(data.previous)
      setLoading(false)
      setPageCount(Math.ceil(data.count / pageSize))
    }
    fetchData()
  }, [page, pageSize, filters])

  const nextPage = () => {
    setPage(page + 1)
  }

  const prevPage = () => {
    if (page > 0) setPage(page - 1)
  }
  if (loading && !data) {
    return <Loader />
  }

  const onFilterChanged = (key) => (value) => {
    setFilters({ ...filters, [key]: value })
  }

  const onSelectedChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value?.value })
  }

  const onSort = (val) => {
    setFilters({ ...filters, ordering: val })
  }

  return (
    <div>
      <div className="m-4 flex">
        <div className="w-1/3">
          <RunsDropdown
            onChange={onFilterChanged("run__id")}
            isClearable={true}
            name="id"
            defaultValue={{
              value: run.runs.selected.id,
              label: run.runs.selected.name,
            }}
            placeholder="Search by Run"
          />
        </div>
      </div>
      <Table
        data={data.results}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        canPrevPage={canPrevPage}
        canNextPage={canNextPage}
        prevPage={prevPage}
        nextPage={nextPage}
        loading={loading}
        pageCount={pageCount}
        onSort={onSort}
      />
    </div>
  )
}

const Table = ({
  noPagination,
  onSizeChange,
  onSort,
  controledPageSize,
  data,
  page,
  pageSize,
  setPageSize,
  canPrevPage,
  canNextPage,
  prevPage,
  nextPage,
  pageCount,
  loading,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "run",
        accessor: "run__id",
        Cell: ({ row }) => {
          return row.original.run.id
        },
      },
      {
        Header: "loan_id",
        accessor: "loan_id",
        Cell: ({ row }) => {
          return row.original.loan_id
        },
      },
      {
        Header: "amortized",
        accessor: "amortized",
        Cell: ({ row }) => {
          return formatNumber(row.original.amortized)
        },
      },
      {
        Header: "remaining",
        accessor: "remaining",
        Cell: ({ row }) => {
          return formatNumber(row.original.remaining)
        },
      },
      {
        Header: "dtm",
        accessor: "dtm",
        Cell: ({ row }) => {
          return formatNumber(row.original.dtm)
        },
      },
      {
        Header: "remaining_prev",
        accessor: "remaining_prev",
        Cell: ({ row }) => {
          return formatNumber(row.original.remaining_prev)
        },
      },
      {
        Header: "mtm_start",
        accessor: "mtm_start",
        Cell: ({ row }) => {
          return formatNumber(row.original.mtm_start)
        },
      },
      {
        Header: "mtm_r_delta",
        accessor: "mtm_r_delta",
        Cell: ({ row }) => {
          return formatNumber(row.original.mtm_r_delta)
        },
      },
      {
        Header: "selected",
        accessor: "selected",
        Cell: ({ row }) => {
          return formatNumber(row.original.selected)
        },
      },
      {
        Header: "on_prev",
        accessor: "on_prev",
        Cell: ({ row }) => {
          return formatNumber(row.original.on_prev)
        },
      }
    ],
    []
  )

  const dataMemo = useMemo(() => data, [data])
  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      manualPagination: true,
      manualSortBy: true,
      columns,
      data: dataMemo,
      initialState: { pageIndex: 0, hiddenColumns: ["id"] },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (!isFirstRender.current) {
      if (!onSort) return
      if (sortBy.length > 0) {
        // creating a sort string that django understands
        const sortStr = `${sortBy[0].desc ? "-" : ""}${sortBy[0].id.replace(
          /\./g,
          "__"
        )}`
        onSort(sortStr)
      } else {
        onSort(null)
      }
    } else {
      isFirstRender.current = false
    }
  }, [sortBy])
  // Render the UI for your table

  return (
    <>
      <div className="table-container-outer">
        {loading ? (
          <div className="absolute z-50 top-0 right-0 left-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center">
            <Loader />
          </div>
        ) : null}
        <table {...getTableProps()} className="table overflow-x-scroll block">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      {/* Add a sort direction indicator */}
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                  {/* <td>test</td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row items-center justify-between my-4">
        <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
          {/* {pageIndex !== 0 && <PageWithText onClick={() => gotoPage(0)}>First</PageWithText>} */}
          <PageWithText onClick={prevPage} disabled={!canPrevPage || loading}>
            Previous
          </PageWithText>

          <PageWithText onClick={nextPage} disabled={!canNextPage || loading}>
            Next
          </PageWithText>
          {/* {pageIndex !== pageCount - 1 && (
            <PageWithText onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              Last
            </PageWithText>
          )} */}
        </div>

        {noPagination || (
          <>
            <span>
              Page{" "}
              <b>
                {page} of {pageCount}
              </b>{" "}
            </span>
            <select
              className="form-select text-sm bg-white dark:bg-gray-800 dark:border-gray-800 outline-none shadow-none focus:shadow-none"
              value={controledPageSize || pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50, 100].map((ps) => (
                <option key={ps} value={ps}>
                  Show {ps}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
      {/* <pre>
        {JSON.stringify(
          data.filter((item, index) =>
            Object.keys(selectedRowIds)
              .map((i) => parseInt(i, 10))
              .includes(index)
          ),
          null,
          2
        )}
      </pre> */}
    </>
  )
}

export default connect(({ run }) => ({
  run: run,
}))(AmortizationScheduleDatatable)
