import PropTypes from 'prop-types'
import React from 'react'
import Datetime from 'react-datetime'
import "./index.css";

const Daypicker = ({
  onChange,
  dateFormat = 'DD-MM-YYYY',
  placeholder,
  inputClassName = null,
  isValidDate = null,
}) => {
  const extraProps = {}
  if (isValidDate) extraProps.isValidDate = isValidDate
  return (
    <Datetime
      defaultValue={new Date()}
      dateFormat="DD-MM-YYYY"
      timeFormat={false}
      input
      inputProps={{
        className: inputClassName ? inputClassName : 'form-input',
        placeholder: placeholder || dateFormat,
      }}
      initialViewMode="days"
      onChange={onChange}
      closeOnSelect
      {...extraProps}
    />
  )
}

Daypicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

Daypicker.defaultProps = {
  placeholder: '',
}

export default Daypicker
