import { useState, useEffect, useRef } from 'react'

export default function useModal() {
  const modalRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const show = () => {
    setIsOpen(true)
  }
  const hide = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false
      if (!isOpen || modalRef.current.contains(event.target)) return false
      setIsOpen(!isOpen)
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, modalRef])

  return [isOpen, show, hide, modalRef]
}
