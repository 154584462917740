import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import reducers from './reducers'

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const middlewares = [thunk]

if (process.env.REACT_APP_ENV !== 'prod') {
  middlewares.push(logger)
}

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)))

export default store
