import { useState } from 'react'

export default function useFormField(schema, label, defaultValue = null) {
  const isRequired = schema.spec?.presence === 'required'
  const [value, setValue] = useState(defaultValue)
  const [error, setError] = useState()
  const [isModified, setIsModified] = useState(false)

  const validate = (value) => {
    setError(null)
    schema.validate(value).catch((err) => {
      setError(getErrorMsg(err, label))
    })
  }

  const onChange = (val) => {
    setValue(val)
    validate(val)
    setIsModified(true)
  }

  const isValid = () =>
    !error && (isRequired ? isModified || defaultValue : true) && !value?.isInvalidOptionReactSelect

  return [value, onChange, error, isValid, validate]
}

const getErrorMsg = (err, label) => {
  switch (err.type) {
    case 'typeError':
    case 'required':
      return `${label} is required`
    case 'min':
      return `${label} must be at least ${err.params.min} minutes`
    case 'oneOf':
      return `${label} doesn't match`
    case 'integer':
      return `${label} must be a whole number`
    case 'email':
      return `Please enter a valid email`
    default:
      return err.message || `Invalid input`
  }
}
