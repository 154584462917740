export const CHECKPOINT_SET = 'CHECKPOINT_SET'
export const HEDGE_SET = 'HEDGE_SET'
export const HEDGE_INITIALIZE = 'HEDGE_INITIALIZE'
export const HEDGE_INITIALIZE_ERROR = 'HEDGE_INITIALIZE_ERROR'
export const HEDGE_LOADING = 'HEDGE_LOADING'
export const HEDGE_CLEAN = 'HEDGE_CLEAN'
export const HEDGE_IMPORT_LOADING = 'HEDGE_IMPORT_LOADING'
export const HEDGE_IMPORT = 'HEDGE_IMPORT'
export const HEDGE_IMPORT_ERROR = 'HEDGE_IMPORT_ERROR'
export const HEDGE_ERROR = 'HEDGE_ERROR'
