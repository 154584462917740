import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { FiLogIn, FiGrid, FiUser } from 'react-icons/fi'
import { logoutUser } from 'store/actions/user'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { BiCube } from 'react-icons/bi'
import LinkItem from './link-item'

const AccountLinks = ({ user }) => {
  const [logout, doLogout] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (logout) {
      dispatch(logoutUser(user))
      navigate('/login', { replace: true })
    }
  }, [dispatch, navigate, logout, user])

  const onLogout = () => {
    doLogout(true)
  }

  const getItems = () => [
    // {
    //   Icon: FiUser,
    //   label: 'Profile',
    //   onClick: () => navigate('/profile'),
    // },
    {
      Icon: FiLogIn,
      label: 'Logout',
      onClick: onLogout,
    },
  ]

  return (
    <div className="flex flex-col w-full bg-white rounded">
      <ul className="list-none">
        {getItems().map((item, i) => (
          <li className="dropdown-item" key={`li_${i}`}>
            <LinkItem {...item} key={i} />
          </li>
        ))}
      </ul>
    </div>
  )
}

AccountLinks.propTypes = {
  user: PropTypes.shape({}).isRequired
}

export default connect(({ user, roles }) => ({ user }))(AccountLinks)
