import PropTypes from 'prop-types'
import RadioButton from '../radio-button'

const RadioGroup = ({ options, name, value, onRadioClick }) => {
  return (
    <div className="flex items-center justify-around space-x-2">
      {options.map((item) => (
        <RadioButton value={value} item={item} onClick={onRadioClick} name={name} />
      ))}
    </div>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onRadioClick: PropTypes.func.isRequired,
}

export default RadioGroup
