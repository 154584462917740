import { FiMenu } from "react-icons/fi"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Link } from "react-router-dom"
import LogoDark from "components/logo/logo-dark"

const Logo = () => {
  const dispatch = useDispatch()
  const { config, leftSidebar } = useSelector(
    (state) => ({
      config: state.config,
      leftSidebar: state.leftSidebar,
    }),
    shallowEqual
  )
  const { collapsed } = { ...config }
  const { showLogo } = { ...leftSidebar }
  if (showLogo) {
    return (
      <>
        <div className="truncate">
          <div className="">
            <Link to="/hedges/latest">
              <LogoDark />
            </Link>
          </div>
          <button
            type="button"
            onClick={() =>
              dispatch({
                type: "SET_CONFIG_KEY",
                key: "collapsed",
                value: !collapsed,
              })
            }
            className="ml-auto mr-4 block lg:hidden"
          >
            <FiMenu size={20} />
          </button>
        </div>
      </>
    )
  }
  return null
}

export default Logo
