import LogoLight from 'components/logo/logo-light'
import Text from 'components/auth-form/text'
import Footer from 'components/auth-form/footer'

const AuthPageLayout = ({ children, textTitle, textContent, formTitle }) => {
  return (
    <div className="w-full flex flex-row h-screen overflow-hidden">
      <div className="hidden lg:flex lg:flex-col w-1/2 text-white p-8 items-start justify-between relative bg-login">
        <span />
        <Text textTitle={textTitle} textContent={textContent} />
        <Footer />
      </div>
      <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
        <LogoLight />
        <p className="text-xl font-bold text-blue-500 mb-4 mt-4">{formTitle}</p>
        {children}
      </div>
    </div>
  )
}

export default AuthPageLayout
