import {
  FaWallet,
  FaFire,
  FaCalendarAlt,
} from "react-icons/fa";
import { connect } from "react-redux";
import BlueBox from "components/hedge/box/blue";
import YellowBox from "components/hedge/box/yellow";
import SectionTitle from "components/section-title";
import GreenBox from "components/hedge/box/green";
import Loader from "components/loader";
import Datatable from "components/datatable";
import Widget from "components/widget";
import { formatNumber } from "utils/text";
import RedBox from "components/hedge/box/red";

const HedgedItemPage = ({ run }) => {
  if (run.runs.isLoading)
    return (
      <>
        <Loader />
      </>
    );

  const columns = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Loan Notionals",
      accessor: "value",
    },
  ];

  var data = run.runs.selected?.output?.hedge_to_ui;

  const hedgedItemInfoTable = data.hedged_item.data_table.Year.map((date, index) => ({
    date: date,
    value: formatNumber(data.hedged_item.data_table["Loan Notionals"][index])
  }))

  return (
    <>
      <SectionTitle subtitle="Hedged Item" />
      <div className="flex gap-4 mb-4">
        <GreenBox
          bgColor="green-400"
          text="Balance of Hedged Item"
          value={formatNumber(data.hedged_item.boxes["Loan Balance"])}
          Icon={FaCalendarAlt}
        />
        <BlueBox
          bgColor="blue-400"
          text="Remaining Amortization"
          value={formatNumber(data.hedged_item.boxes["Remaining Amortization"])}
          unit=""
          Icon={FaWallet}
        />
        <YellowBox
          bgColor="yellow-400"
          text="MtM of Hedged Item"
          value={formatNumber(data.hedged_item.boxes["Mark-to-Market"])}
          unit=""
          Icon={FaFire}
        />
        <RedBox
          bgColor="yellow-400"
          text="Delta MtM of Hedged Item"
          value={formatNumber(data.hedged_item.boxes["MtM Delta"])}
          unit=""
          Icon={FaFire}
        />

      </div>
      <Widget>
        <Datatable columns={columns} data={hedgedItemInfoTable} />
      </Widget>
    </>
  );
};

export default connect(({ run }) => ({ run }))(HedgedItemPage);
