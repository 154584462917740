import {
  HEDGE_INITIALIZE,
  HEDGE_SET,
  CHECKPOINT_SET,
  HEDGE_LOADING,
  HEDGE_CLEAN,
  HEDGE_IMPORT_LOADING,
  HEDGE_IMPORT_ERROR,
  HEDGE_ERROR,
  HEDGE_INITIALIZE_ERROR,
} from '../../actions/hedge/types'

const initialState = {
  hedges: {
    selected: null,
    selectedCheckpoint: null,
    isLoading: true,
    data: null,
  },
  selectedResult: null,
  checkpoints: {
    data: null,
    selected: null,
  },
}

export default function hedge(state = initialState, action) {
  switch (action.type) {
    case HEDGE_LOADING: {
      const newState = { ...state }
      newState.hedges.isLoading = action.isLoading
      return newState
    }
    case HEDGE_INITIALIZE: {
      return { ...state, ...action.payload, error: null }
    }
    case HEDGE_INITIALIZE_ERROR: {
      return { ...state, hedges: { ...initialState.hedges, isLoading: false }, error: action.error }
    }
    case HEDGE_ERROR: {
      return { ...state, hedges: { ...state.hedges, isLoading: false }, error: action.error }
    }
    case HEDGE_SET: {
      action.payload.hedges.data = state.hedges.data
      return { ...state, ...action.payload }
    }
    case CHECKPOINT_SET: {
      action.payload.hedges.data = state.hedges.data
      return { ...state, ...action.payload }
    }
    case HEDGE_CLEAN: {
      return initialState
    }
    case HEDGE_IMPORT_LOADING: {
      return {
        ...state,
        error: null,
        hedges: { ...state.hedges, isImportLoading: true },
      }
    }
    case HEDGE_IMPORT_ERROR: {
      return {
        ...state,
        error: action.error,
        hedges: { ...state.hedges, isImportLoading: false },
      }
    }
    default:
      return state
  }
}
