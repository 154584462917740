import RunsTable from "components/runs/runs-table";
import SectionTitle from "components/section-title";

export default function RunLogs() {
  return (
    <>
      <SectionTitle subtitle="Run Logs" />
      <RunsTable />
    </>
  );
}
