const saveExcelFile = (() => {
    if (typeof document !== 'undefined') {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      return (data, fileName, type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') => {
        const blob = new Blob([data], { type })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }
    }
  })()
  
  export default saveExcelFile