const LinkItem = ({ onClick, label, Icon }) => {
  return (
    <button
      type="button"
      className="flex flex-row items-center justify-start h-10 w-full px-2 hover:bg-gray-50 "
      onClick={onClick}
    >
      <Icon size={18} className="stroke-current" />
      <span className="mx-2">{label}</span>
    </button>
  )
}

export default LinkItem
