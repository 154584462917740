import logoPng from 'assets/images/hedgeblue_logo.png'

const LogoLight = () => {
  return (
    <span className="logo flex flex-row items-center justify-start space-x-2">
      <img src={logoPng} alt="logo" className="w-36" />
      <span className="text-gray-600 text-3xs font-bold align-middle">BETA</span>
    </span>
  )
}

export default LogoLight
