import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "components/buttons";
import { startTask } from "store/actions/action-items";
import SectionTitle from "components/section-title";
import Widget from "components/widget";
import ResponsiveContainer from "components/containers/responsive";
import FormElement from "components/forms/form-element";
import useFormField from "hooks/validators/useFormField";
import { useState } from "react";
import Alert from "components/alerts";
import Daypicker from "components/forms/daypicker";
import {
  requiredString,
  requiredNumber,
  timeLimitSchema,
  prospectiveLowerLimitSchema,
  prospectiveUpperLimitSchema,
  prospectiveMTMDeltaMultiplierSchema,
} from "utils/schemas";
import moment from "moment";
import TextInput from "components/forms/text-input";
import NumberInput from "components/forms/number-input";
import useModal from "hooks/useModal";
import Modal from "components/modal";
import RadioGroup from "components/forms/radio-group";
import {
  PROSPECTIVE_LOWER_LIMIT_DEFAULT,
  PROSPECTIVE_UPPER_LIMIT_DEFAULT,
  PROSPECTIVE_MTM_DELTA_MULTIPLIER,
} from "utils/constants";
import { generateName } from "utils/naming";

const CreateRunOrdersForm = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultName = generateName();

  const [serverError, setServerError] = useState(false);
  const [periodInvalid, setPeriodInvalid] = useState(null);

  const [isModalOpen, showModal, hideModal, refModal] = useModal();

  const amortizationScheduleRadioOptions = [
    { value: "1", label: "Standard" },
    { value: "2", label: "MTM Adjustment" },
  ];
  const [standartOrMtmAdjustment, setStandartOrMtmAdjustment, isValidSORM] =
    useFormField(requiredString, "Standard", "2");
  const [period, setPeriod, errorD, isValidD] = useFormField(
    requiredString,
    "Valuation Date"
  );
  const [name, setName, errorHN, isValidHN] = useFormField(
    requiredString,
    "Run name",
    defaultName
  );
  const [effectivenessLambda, setEffectivenessLambda, errorEL, isValidEL] =
    useFormField(requiredNumber, "Effectiveness Lambda", 0);
  const [timeLimit, setTimeLimit, errorTL, isValidTL] = useFormField(
    timeLimitSchema,
    "Time Limit",
    5
  );

  const [hedgeStartInvalid, setHedgeStartInvalid] = useState(null);

  const [hedgeStart, setHedgeStart, errorHedgeStartD, isValidHedgeStartD] =
    useFormField(requiredString, "Hedge Start");
  const [pLowerLimit, setPLowerLimit, errorPLL, isValidPLL] = useFormField(
    prospectiveLowerLimitSchema(PROSPECTIVE_UPPER_LIMIT_DEFAULT),
    "Prospective Lower Limit",
    PROSPECTIVE_LOWER_LIMIT_DEFAULT
  );

  const [pUpperLimit, setPUpperLimit, errorPUL, isValidPUL] = useFormField(
    prospectiveUpperLimitSchema(pLowerLimit),
    "Prospective Upper Limit",
    PROSPECTIVE_UPPER_LIMIT_DEFAULT
  );

  const [pMDMultiplier, setPMDMultiplier, errorPMDM, isValidPMDM] =
    useFormField(
      prospectiveMTMDeltaMultiplierSchema,
      "Prospective MTM Delta Multiplier",
      PROSPECTIVE_MTM_DELTA_MULTIPLIER
    );

  const today = moment();
  const isValidPeriod = (current) => {
    return current.isSame("2024-05-31") || current.isSame("2024-06-30");
  };

  const isValidHedgeStart = (current) => {
    const hedgeStart = current.clone().add("month", -1).endOf("month");
    return hedgeStart;
  };
  const [formData, setFormData] = useState(new FormData());

  const onSubmit = (e) => {
    e.preventDefault();
    formData.append("period", period.format("YYYY-MM-DD"));
    formData.append("name", name);
    formData.append("timeLimit", timeLimit);

    if (hedgeStart)
      formData.append("hedgeStart", hedgeStart.format("YYYY-MM-DD"));
    formData.append("pUpperLimit", pUpperLimit);
    formData.append("pLowerLimit", pLowerLimit);
    formData.append("effectivenessLambda", effectivenessLambda);
    formData.append("pMDMultiplier", pMDMultiplier);
    formData.append("standartOrMtmAdjustment", standartOrMtmAdjustment);

    dispatch(startTask(formData)).then((res) => {
      navigate(`/runs/run/${res.id}`);
    });
  };

  const onAdvancedSettings = (e) => {
    hideModal();
  };
  return (
    <>
      <SectionTitle subtitle="Run FVPH" />
      <Widget>
        <ResponsiveContainer>
          {serverError && (
            <div className="mb-4">
              <Alert
                color="bg-transparent border-red-500 text-red-500"
                borderLeft
                raised
              >
                {serverError}
              </Alert>
            </div>
          )}
          <form onSubmit={onSubmit}>
            <FormElement label="Choose Date" error={errorD || periodInvalid}>
              <Daypicker
                onChange={(val) => {
                  setPeriod(val);
                  setPeriodInvalid(null);
                }}
                error={errorD || periodInvalid}
                isValidDate={isValidPeriod}
              />
            </FormElement>
            <FormElement label="Run Label" error={errorHN}>
              <TextInput
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Give a name to your run"
              />
            </FormElement>
            <FormElement label="Time Limit in Minutes" error={errorTL}>
              <NumberInput
                max={20}
                min={2}
                step={1}
                onChange={(e) => setTimeLimit(e.target.value)}
                error={errorTL}
                value={timeLimit}
              />
            </FormElement>
            <Button
              type="button"
              onClick={showModal}
              className="mb-4"
              secondary
            >
              Advanced Settings
            </Button>
            <br />
            <hr />
            <br />
            <Button>Run FVPH</Button>
          </form>
          {isModalOpen && (
            <Modal
              buttonTitle="Save Changes"
              onButtonClick={onAdvancedSettings}
              hideButtonTitle="Close"
              title="Advanced Settings"
              body={
                <div className="text-sm text-gray-500">
                  <form>
                    <FormElement
                      label="Choose Hedge Start"
                      error={errorHedgeStartD || hedgeStartInvalid}
                    >
                      <Daypicker
                        onChange={(val) => {
                          setHedgeStart(val);
                          setHedgeStartInvalid(null);
                        }}
                        error={errorHedgeStartD || hedgeStartInvalid}
                        isValidDate={isValidHedgeStart}
                      />
                    </FormElement>
                    <FormElement
                      label="Prospective Upper Limit"
                      error={errorPUL}
                    >
                      <NumberInput
                        onChange={(e) => setPUpperLimit(e.target.value)}
                        error={errorPUL}
                        value={pUpperLimit}
                        max={PROSPECTIVE_UPPER_LIMIT_DEFAULT}
                        min={pLowerLimit + 0.005}
                        step={0.005}
                      />
                    </FormElement>
                    <FormElement
                      label="Prospective Lower Limit"
                      error={errorPLL}
                    >
                      <NumberInput
                        onChange={(e) => setPLowerLimit(e.target.value)}
                        error={errorPLL}
                        value={pLowerLimit}
                        min={PROSPECTIVE_LOWER_LIMIT_DEFAULT}
                        max={pUpperLimit - 0.005}
                        step={0.005}
                      />
                    </FormElement>
                    <FormElement label="Effectiveness Lambda" error={errorEL}>
                      <NumberInput
                        onChange={(e) => setEffectivenessLambda(e.target.value)}
                        error={errorEL}
                        value={effectivenessLambda}
                        step={10000}
                      />
                    </FormElement>
                    <FormElement
                      label="Prospective MTM Delta Multiplier"
                      error={errorPMDM}
                    >
                      <NumberInput
                        max={10}
                        min={0.001}
                        step={0.01}
                        onChange={(e) => setPMDMultiplier(e.target.value)}
                        error={errorPMDM}
                        value={pMDMultiplier}
                      />
                    </FormElement>
                    <FormElement label="Amortization Schedule Method">
                      <RadioGroup
                        value={standartOrMtmAdjustment}
                        onRadioClick={(val) => {
                          if (!val) {
                            setStandartOrMtmAdjustment(null);
                          }
                          setStandartOrMtmAdjustment(val);
                        }}
                        // onRadioClick={setStandartOrMtmAdjustment}
                        name="amortizationScheduleMethod"
                        options={amortizationScheduleRadioOptions}
                      />
                    </FormElement>
                  </form>
                </div>
              }
              hide={hideModal}
              ref={refModal}
            />
          )}
        </ResponsiveContainer>
      </Widget>
    </>
  );
};

export default connect(({ user }) => ({
  user,
}))(CreateRunOrdersForm);
