import { connect, useDispatch } from "react-redux"
import { useEffect } from "react"
import { setExceptions } from "store/actions/ha-exceptions"
import ManagementSubLayout from "layouts/management/exception-sub"
import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import TextCell from "components/management/expanded-text-cell"

const ExceptionsPage = ({ exceptions, filters }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!exceptions.data?.length) {
      dispatch(setExceptions(filters))
    }
  }, [dispatch, filters])

  return (
    <ManagementSubLayout
      title="Exceptions"
      filters={filters}
      data={exceptions}
      mainColumns={[
        {
          Header: "Exceptions",
          accessor: "exceptions",
          Cell: TextCell,
        },
        {
          Header: "Created at",
          accessor: "created_at",
          Cell: ({ row }) => {
            if (row.original.created_at) {
              return format(
                parseISO(row.original.created_at),
                "dd/MM/yyyy HH:mm:ss"
              )
            }
            return "-"
          },
        },
      ]}
    />
  )
}

ExceptionsPage.propTypes = {
  exceptions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}
export default connect(({ exceptions }) => ({
  exceptions: exceptions.exceptions,
  filters: exceptions.exceptions.filters,
}))(ExceptionsPage)
