import {
  EXCEPTIONS_FETCHING,
  EXCEPTIONS_SET,
  EXCEPTIONS_FILTERS,
  EXCEPTIONS_NEXT_PAGE,
  EXCEPTIONS_PREV_PAGE,
  EXCEPTIONS_CLEAR,
} from "store/actions/ha-exceptions/types"

const initialState = {
  users: {
    isLoading: true,
    updating: null,
    data: [],
  },
  exceptions: {
    isLoading: true,
    updating: null,
    data: [],
    filters: { page: 1, page_size: 10 },
  },
}

export default function exceptions(state = initialState, action) {
  switch (action.type) {
    case EXCEPTIONS_FETCHING:
      return { ...state, exceptions: { ...state.exceptions, isLoading: true } }

    case EXCEPTIONS_SET: {
      return {
        ...state,
        exceptions: {
          ...state.exceptions,
          isLoading: false,
          data: action.payload,
        },
      }
    }

    case EXCEPTIONS_FILTERS:
      return {
        ...state,
        exceptions: {
          ...state.exceptions,
          filters: { ...state.exceptions.filters, ...action.payload },
        },
      }

    case EXCEPTIONS_NEXT_PAGE: {
      const filters = { ...state.exceptions.filters }
      filters.page++
      return { ...state, exceptions: { ...state.exceptions, filters } }
    }

    case EXCEPTIONS_PREV_PAGE: {
      const filters = { ...state.exceptions.filters }
      if (filters.page > 1) filters.page--
      return { ...state, exceptions: { ...state.exceptions, filters } }
    }
    case EXCEPTIONS_CLEAR:
      return { ...initialState.exceptions }
    default:
      return state
  }
}
