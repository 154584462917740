import {
  COMPANIES_CREATE,
  COMPANIES_DELETE,
  COMPANIES_SET,
  COMPANIES_UPDATE,
} from 'store/actions/management/types'

const initialState = {
  companies: {
    isLoading: true,
    updating: null,
    data: [],
  },
  users: {
    isLoading: true,
    updating: null,
    data: [],
  },
}

export default function management(state = initialState, action) {
  switch (action.type) {
    case COMPANIES_SET: {
      return {
        ...state,
        companies: {
          isLoading: false,
          data: action.payload,
        },
      }
    }
    case COMPANIES_DELETE: {
      return {
        ...state,
        companies: {
          isLoading: false,
          updating: null,
          data: state.companies.data.filter((comp) => comp.id !== action.id),
        },
      }
    }
    case COMPANIES_UPDATE: {
      if (state.companies.updating) {
        return {
          ...state,
          companies: {
            ...state.companies,
            updating: null,
            data: state.companies.data.map((org) =>
              org.id === action.payload.id ? action.payload : org
            ),
          },
        }
      }
      return {
        ...state,
        companies: {
          ...state.companies,
          updating: action.payload,
        },
      }
    }
    case COMPANIES_CREATE: {
      return {
        ...state,
        companies: {
          ...state.companies,
          data: [...state.companies.data, action.payload],
        },
      }
    }

    default:
      return state
  }
}
