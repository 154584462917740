import {
  USERS_DELETE,
  USERS_SET,
  USERS_SET_UPDATING,
  USERS_ACTIVATE,
} from 'store/actions/user-management/types'

const initialState = {
  isLoading: true,
  updating: null,
  data: [],
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case USERS_SET:
      return { isLoading: false, data: action.payload }
    case USERS_DELETE:
      return { ...state, data: state.data.filter((user) => user.id !== action.userId) }
    case USERS_SET_UPDATING:
      return { ...state, updating: action.payload }
    case USERS_ACTIVATE:
      return {
        ...state,
        data: state.data.map((user) =>
          user.id === action.payload && !user.is_active
            ? { ...user, is_active: !user.is_active }
            : user
        ),
      }
    default:
      return state
  }
}
