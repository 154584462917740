import PropTypes from 'prop-types'

const ResponsiveContainer = ({ children }) => {
  return <div className="lg:w-1/2 2xl:w-2/5">{children}</div>
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ResponsiveContainer
