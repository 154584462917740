import PropTypes from 'prop-types'

const NumberInput = (props) => {
  const { error } = props
  return (
    <input className={`form-input ${error ? 'form-input-invalid' : ''}`} type="number" lang="tr_TR" {...props} />
  )
}

NumberInput.propTypes = {
  error: PropTypes.string,
}

NumberInput.defaultProps = {
  error: '',
}

export default NumberInput
