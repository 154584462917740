import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { loginUser } from 'store/actions/user'
import { useNavigate } from 'react-router-dom'
import Validation from '../forms/validation'
import Alert from '../alerts'

const AuthForm = ({ items, user }) => {
  const [data, onSubmit] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (data) {
      dispatch(loginUser(data, navigate))
    }
  }, [data])

  return (
    <>
      <div className="flex flex-col">
        {user.isError && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-red-500 text-red-500" borderLeft raised>
              {user.error.response
                ? user.error.response.data.fallback_message
                : 'An error occured, please try again later.'}
            </Alert>
          </div>
        )}
        <Validation items={items} onSubmit={onSubmit} isLoading={user.isLoading} />
      </div>
    </>
  )
}

export default connect(({ user }) => ({ user }))(AuthForm)
