import { FaBalanceScale, FaWallet, FaFire } from "react-icons/fa"
import { connect } from "react-redux"
import BlueBox from "components/hedge/box/blue"
import YellowBox from "components/hedge/box/yellow"
import SectionTitle from "components/section-title"
import GreenBox from "components/hedge/box/green"
import Loader from "components/loader"
import Datatable from "components/datatable"
import Widget from "components/widget"
import ReactECharts from "echarts-for-react"
import { formatNumber } from "utils/text";

const LatestHedgePage = ({ run }) => {
  if (run.runs.isLoading)
    return (
      <>
        <Loader />
      </>
    )
  if (run.error?.status === 401) {
    window.location.href = "/login"
  }
  const columns = [
    {
      Header: "",
      accessor: "label",
    },
    {
      Header: "",
      accessor: "value",
    },
  ]

  var data = run.runs.selected?.output?.hedge_to_ui;

  const hedgeInfoTable = data?.main?.table ? Object.keys(data?.main?.table).map(key => ({
    label: key,
    value: data?.main?.table[key]
  })): []

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          show: true,
        },
      },
      // formatter(param) {
      //   return [
      //     `${param[0].name}<hr size=1 style="margin: 3px 0">`,
      //     `${param[0].marker} ${param[0].seriesName}: ${param[0].data}<br/>`,
      //     `${param[1].marker} Close: ${param[1].data}<br/>`,
      //   ].join('')
      // },
    },
    toolbox: {
      show: true,
      feature: {
        /* mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true }, */
        saveAsImage: { show: true, name: `${run.runs.selected?.name} notionals` },
      },
    },
    calculable: true,
    legend: {
      data: ['Date', 'Derivative Notionals', 'Loan Notionals'],
      itemGap: 5,
    },
    grid: {
      top: '12%',
      left: '1%',
      right: '10%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: data.main.bar_plot.Year,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        axisLabel: {
          formatter(a) {
            a = +a
            return ''
          },
        },
      },
    ],
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 100,
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        show: true,
        yAxisIndex: 0,
        filterMode: 'empty',
        width: 30,
        height: '80%',
        showDataShadow: false,
        left: '93%',
      },
    ],
    series: [
      {
        name: 'Derivative Notionals',
        type: 'bar',
        data: data.main.bar_plot["Derivative Notionals"],
      },
      {
        name: 'Loan Notionals',
        type: 'bar',
        data: data.main.bar_plot["Loan Notionals"],
      },
    ],
  })

  const getAreaOption = () => ({
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.main.amortization_plot.Year,
    },
    yAxis: {
      type: 'value',
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          name: `${run.runs.selected?.name} remaining notionals`,
        },
      },
    },
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 100,
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        show: true,
        yAxisIndex: 0,
        filterMode: 'empty',
        width: 30,
        height: '80%',
        showDataShadow: false,
        left: '93%',
      },
    ],
    series: [
      {
        data: data?.main?.amortization_plot["Remaining Amortization"],
        type: 'line',
        areaStyle: {},
      },
    ],
  })

  return (
    <>
      <SectionTitle subtitle={`Hedge Summary of Run: ${run.runs.selected?.name}`} />
      <div className="flex gap-4 mb-4">
        <GreenBox
          text="Retrospective Effectiveness Rate"
          unit=""
          value={formatNumber(data?.main?.boxes["Retrospective Effectiveness Rate"])}
          Icon={FaBalanceScale}
        />
        <BlueBox text="Ineffectiveness Amount" unit="" Icon={FaWallet} value={formatNumber(data?.main?.boxes["Ineffectiveness Amount"])} />
        <YellowBox text="Amortized Amount" unit="" Icon={FaFire} value={formatNumber(data?.main?.boxes["Amortized Amount"])} />
      </div>
      <div className="flex flex-col">
        <div className="w-full">
          <Widget title="Hedge Info">
            <div className="w-full mb-4">
              <Datatable columns={columns} data={hedgeInfoTable} noPagination />
            </div>
          </Widget>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-full">
          <Widget title="Notionals" description="">
            <div className="w-full mb-4">
              <ReactECharts
                option={getOption()}
                notMerge
                lazyUpdate
                // theme={'theme_name'}
                // onChartReady={}
                // onEvents={}
                // opts={}
              />
            </div>
          </Widget>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-full">
          <Widget title="Remaining Notionals" description="">
            <div className="w-full mb-4">
              <ReactECharts
                option={getAreaOption()}
                notMerge
                lazyUpdate
                // theme={'theme_name'}
                // onChartReady={}
                // onEvents={}
                // opts={}
              />
            </div>
          </Widget>
        </div>
      </div>


    </>
  )
}

export default connect(({ run }) => ({ run }))(LatestHedgePage)
