import { Link } from 'react-router-dom'
import logo from 'assets/images/hedgeblue_logo.png'
import Dropdown5 from './dropdown-5'

const CleanNavbar = ({ hideLogo }) => {
  return (
    <div className="navbar navbar-1 bg-white border-b px-2 md:px-4">
      <div className="navbar-inner w-full flex items-center justify-start">
        {hideLogo || (
          <Link to="/" className="w-36">
            <img src={logo} alt="logo" />
          </Link>
        )}
        <span className="ml-auto" />
        {/* <DropdownList
          title="Switch to"
          ButtonIcon={IoMdApps}
          items={[
            {
              title: 'Hedge Accounting',
              icon: GiSecretBook,
              href: '/hedge',
            },
            // {
            //   title: 'Forward Valuation',
            //   icon: BiLineChart,
            //   href: '/forward-valuation',
            // },
          ]}
          hide={false}
        /> */}
        <Dropdown5 />
      </div>
    </div>
  )
}

export default CleanNavbar
