import axios from 'axios'
import { AUTH_STORAGE_KEY } from './constants'
import { getItemLocal } from './localStorage'

const client = axios.create({ baseURL: process.env.REACT_APP_API_URL })

client.interceptors.request.use((config) => {
  const tokens = getItemLocal(AUTH_STORAGE_KEY)
  if (tokens) {
    const newConfig = { ...config }
    newConfig.headers.Authorization = `Token ${tokens.auth_token}`
    return newConfig
  }
  return config
})

export { client }
export const unAuthClient = axios.create({ baseURL: process.env.REACT_APP_API_URL })
