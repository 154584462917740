import { useState } from "react"
import SearchableDropdown from "components/searchable-dropdown"
import Widget from "components/widget"
import LoanCashFlowDatatable from "components/datatable/loan-cash-flow-datatable"
import SectionTitle from "components/section-title"
import LoanInfoDatatable from "components/datatable/loan-info-datatable"
import YieldCurveDatatable from "components/datatable/yield-curve-datatable"
import DerivativeInfoDatatable from "components/datatable/derivative-info-datatable"
import DerivativeCashFlowDatatable from "components/datatable/derivative-cash-flow-datatable"
import MatchResultDatatable from "components/datatable/match-result-datatable"
import { client } from "utils/api"
import saveCSVFile from "utils/saveCSVFile"
import { Button } from "components/buttons"
import AccountingEntryDatatable from "components/datatable/accounting-entries-datatable"
import AmortizationScheduleDatatable from "components/datatable/amortization-schedule-datatable"

const DataTablesPage = () => {
  const tableOptions = [
    {
      value: "loan-info",
      label: "Loan Info",
      isInvalidOptionReactSelect: true,
    },
    { value: "loan-cash-flows", label: "Loan Cash Flows" },
    { value: "yield-curves", label: "Yield Curves" },
    { value: "derivative-info", label: "Derivative Info" },
    { value: "derivative-cash-flow", label: "Derivative Cash Flow" },
    { value: "match-result", label: "Match Result" },
    { value: "accounting-entries", label: "Accounting Entries" },
    { value: "amortization-schedule", label: "Amortization Schedule" },
  ]
  const [table, setTable] = useState(tableOptions[0].value)
  const [isExportLoanCashFlow, setIsExportLoanCashFlow] = useState(false)
  const [isExportLoanInfo, setIsExportLoanInfo] = useState(false)
  const [isExportYieldCurve, setIsExportYieldCurve] = useState(false)
  const [isExportDerivativeInfo, setIsExportDerivativeInfo] = useState(false)
  const [isExportDerivativeCashFlow, setIsExportDerivativeCashFlow] = useState(false)
  const [isExportMatchResult, setIsExportMatchResult] = useState(false)

  return (
    <>
      <div className="flex">
        <div className="flex gap-2">
          <div className="-1">
            <SearchableDropdown
              label="Select Table"
              options={tableOptions}
              isClearable
              onChange={(val) => setTable(val && val.value)}
              isClearable={false}
            />
          </div>
        </div>
        {table === tableOptions[0].value ? (
        
        <Button className="ml-5 mt-7 p-4"
          onClick={() => {
            setIsExportLoanInfo(true)
            client
              .get(`/export/loan-info/`)
              .then((res) => {
                saveCSVFile(res.data, `loan_info_data.csv`)
                setIsExportLoanInfo(false)
              })
              .catch(() => {
                setIsExportLoanInfo(false)
              })
          }}
          rounded
          disabled={isExportLoanInfo}
        
        >
          Download Loan Infos
        </Button>
      
      ) : table === tableOptions[1].value ? (
        <div>
          <Button className="ml-5 mt-7"
            onClick={() => {
              setIsExportLoanCashFlow(true)
              client
                .get(`/export/loan-cash-flow/`)
                .then((res) => {
                  saveCSVFile(res.data, `loan_cash_flows_data.csv`)
                  setIsExportLoanCashFlow(false)
                })
                .catch(() => {
                  setIsExportLoanCashFlow(false)
                })
            }}
            rounded
            disabled={isExportLoanCashFlow}
          >
            Download Cash Flows
          </Button>
        </div>
      ) : table === tableOptions[2].value ? (
        <div>
          <Button className="ml-5 mt-7"
            onClick={() => {
              setIsExportYieldCurve(true)
              client
                .get(`/export/yield-curve/`)
                .then((res) => {
                  saveCSVFile(res.data, `yield_curve_data.csv`)
                  setIsExportYieldCurve(false)
                })
                .catch(() => {
                  setIsExportYieldCurve(false)
                })
            }}
            rounded
            disabled={isExportYieldCurve}
          >
            Download Yield Curves
          </Button>
        </div>
      ) : table === tableOptions[3].value ? (
        <div>
        <Button className="ml-5 mt-7"
          onClick={() => {
            setIsExportDerivativeInfo(true)
            client
              .get(`/export/derivative-info/`)
              .then((res) => {
                saveCSVFile(res.data, `derivative_info_data.csv`)
                setIsExportDerivativeInfo(false)
              })
              .catch(() => {
                setIsExportDerivativeInfo(false)
              })
          }}
          rounded
          disabled={isExportDerivativeInfo}
        >
          Download Derivative Infos
        </Button>
      </div>
      ) : table === tableOptions[4].value ? (
        <div>
          <Button className="ml-5 mt-7 p-4"
            onClick={() => {
              setIsExportDerivativeCashFlow(true)
              client
                .get(`/export/derivative-cash-flow/`)
                .then((res) => {
                  saveCSVFile(res.data, `derivative_cash_flow_data.csv`)
                  setIsExportDerivativeCashFlow(false)
                })
                .catch(() => {
                  setIsExportDerivativeCashFlow(false)
                })
            }}
            rounded
            disabled={isExportDerivativeCashFlow}
          >
            Download Derivative Flows
          </Button>
        </div>
      ) : table === tableOptions[5].value ? (
        <div>
        <Button className="ml-5 mt-7"
          onClick={() => {
            setIsExportMatchResult(true)
            client
              .get(`/export/match-result/`)
              .then((res) => {
                saveCSVFile(res.data, `match_result_data.csv`)
                setIsExportMatchResult(false)
              })
              .catch(() => {
                setIsExportMatchResult(false)
              })
          }}
          rounded
          disabled={isExportMatchResult}
        >
          Download Match Results
        </Button>
      </div>
      ) : null}
      </div>

      <SectionTitle title="" subtitle="Datatables" />
      {table === tableOptions[0].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <LoanInfoDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[1].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <LoanCashFlowDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[2].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <YieldCurveDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[3].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <DerivativeInfoDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[4].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <DerivativeCashFlowDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[5].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <MatchResultDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[6].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <AccountingEntryDatatable />
          </Widget>
        </div>
      ) : table === tableOptions[7].value ? (
        <div className="w-[300px] sm:w-[350px] md:w-[550px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1250px] 3xl:w-[1400px]">
          <Widget description="">
            <AmortizationScheduleDatatable />
          </Widget>
        </div>
      ) : null}
    </>
  )
}
export default DataTablesPage
