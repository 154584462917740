import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { cleanHedge } from 'store/actions/hedge'
import { logoutUser } from 'store/actions/user'
import { AUTH_STORAGE_KEY } from 'utils/constants'

const AuthenticatedLayout = ({ user, children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (!user.isLoggedIn && !user.isLoading) {
      navigate('/login')
    }
  }, [user])

  const listener = (e) => {
    if (e.key === AUTH_STORAGE_KEY && !e.newValue) {
      dispatch(logoutUser())
      navigate('/login')
    }
  }

  useEffect(() => {
    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  })

  if (user.isLoggedIn) return <>{children}</>
  return null
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default connect(mapStateToProps)(AuthenticatedLayout)
