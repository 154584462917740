import { connect, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Alert from 'components/alerts'
import AuthPageLayout from 'layouts/auth'
import AuthForm from 'components/auth-form/auth-form'
import useQuery from 'hooks/useQuery'

const Login = ({ user }) => {
  const navigate = useNavigate()
  const [transactionSuccess, settransactionSuccess] = useState(false)
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  )
  const [message, messageExists] = useQuery('message')
  const { name } = { ...config }
  
  useEffect(() => {
    if (user.isLoggedIn && user.user?.email) {
        navigate('/hedges/latest')
    }
    if (messageExists) {
      settransactionSuccess(message)
    }
  }, [user])

  return (
    <AuthPageLayout
      textTitle={`Welcome to ${name}!`}
      textContent="Welcome to Hedgeblue Hedge Accounting portal"
      formTitle="Login"
    >
      {transactionSuccess && (
        <div className="w-full mb-4">
          <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
            {transactionSuccess}
          </Alert>
        </div>
      )}
      <AuthForm items={items} />
      <div className="w-full mt-2">
        <span>
          <Link to="/forgot-password" className="link">
            Forgot password?
          </Link>
        </span>
      </div>
    </AuthPageLayout>
  )
}

let items = [
  {
    label: 'Email',
    error: { required: 'Please enter a valid email' },
    name: 'email',
    type: 'email',
    placeholder: 'Enter you email',
  },
  {
    label: 'Password',
    error: {
      required: 'Password is required',
      minLength: {
        value: 4,
        message: 'Your password should have at least 4 characters',
      },
    },
    name: 'password',
    type: 'password',
    placeholder: 'Enter your password',
  },
]

export default connect(({ user }) => ({ user }))(Login)
