import { AsyncPaginate } from 'react-select-async-paginate'

// works as a formik field if a name is provided
export default function AsyncPaginatedDropdown(props) {
  return <SelectAsyncPaginate {...props} />
}

// must take fetcher and count to work
const SelectAsyncPaginate = ({ fetcher, onChange, defaultValue, form, field, error, ...rest }) => {
  const loadOptions = async (search, loadedOptions, { page, hasMore }) => {
    try {
      const res = await fetcher({ page, search })
      return {
        options: hasMore ? res.results : [],
        hasMore,
        additional: {
          hasMore: res.next,
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  return (
    <AsyncPaginate
      className="w-full"
      loadOptions={loadOptions}
      onChange={(opt) => {
        const val = opt ? opt.value : null
        onChange?.(val)
      }}
      defaultValue={defaultValue || null}
      isSearchable={true}
      styles={{
        control: (styles) => ({
          ...styles,
          borderRadius: '0',
          borderColor: `${error ? '#ef4444' : '#6b7280'}`,
        }),
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: 'rgb(239, 246, 255)',
          labelColor: 'rgb(96, 165, 250)',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#3b82f6',
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          ':hover': {
            color: '#3b82f6',
          },
        }),
      }}

      additional={{
        page: 1,
        hasMore: true,
      }}
      {...rest}
    />
  )
}
