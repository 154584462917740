import PropTypes from 'prop-types'
import FormElement from 'components/forms/form-element'
import Select from 'react-select'

const SearchableDropdown = ({
  label,
  options,
  onChange,
  error,
  hint,
  placeholder,
  isMulti,
  isClearable = true,
  defaultValue = null,
}) => {
  const extraProps = placeholder
    ? { placeholder }
    : defaultValue
    ? { defaultValue }
    : { defaultValue: options[0] }

  return (
    <FormElement label={label} error={error} hint={hint}>
      <Select
        instanceId={`react-select-${label}`}
        options={options}
        isClearable={isClearable}
        isMulti={isMulti}
        onChange={onChange}
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: '0',
            borderColor: `${error ? '#ef4444' : '#6b7280'}`,
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: 'rgb(239, 246, 255)',
            labelColor: 'rgb(96, 165, 250)',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: '#3b82f6',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            ':hover': {
              color: '#3b82f6',
            },
          }),
        }}
        {...extraProps}
      />
    </FormElement>
  )
}

SearchableDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
}

SearchableDropdown.defaultProps = {
  label: '',
  error: null,
  hint: null,
  placeholder: null,
  isMulti: false,
}

export default SearchableDropdown
