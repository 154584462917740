const nouns = [
    "Area",
    "Back",
    "Ball",
    "Band",
    "Base",
    "Book",
    "Call",
    "Card",
    "Case",
    "City",
    "Deal",
    "Door",
    "East",
    "Edge",
    "Fact",
    "Farm",
    "Fear",
    "Fire",
    "Fish",
    "Food",
    "Foot",
    "Form",
    "Game",
    "Goal",
    "Hand",
    "Head",
    "Hill",
    "Home",
    "Hope",
    "Hour",
    "Kind",
    "King",
    "Line",
    "Look",
    "Mark",
    "Mind",
    "Move",
    "Name",
    "Need",
    "News",
    "Note",
    "Pair",
    "Park",
    "Path",
    "Race",
    "Rain",
    "Rise",
    "Road",
    "Rock",
    "Room",
    "Seat",
    "Show",
    "Sign",
    "Star",
    "Town",
    "Tree",
    "Turn",
    "Wall",
    "West",
    "Wind",
    "Wood",
    "Bear",
    "Ring",
    "Suit",
]
const adjectives = [
    "Blue",
    "Bold",
    "Calm",
    "Cold",
    "Cool",
    "Deep",
    "Fair",
    "Fast",
    "Fine",
    "Firm",
    "Glad",
    "Grey",
    "High",
    "Kind",
    "Neat",
    "Nice",
    "Pink",
    "Pure",
    "Rare",
    "Real",
    "Soft",
    "Tall",
    "Tidy",
    "Warm",
    "Wide",
    "Wild",
    "Wise",
    "Solo",
]

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}  

export function generateName() {
    return adjectives[randomIntFromInterval(0, adjectives.length - 1)] + nouns[randomIntFromInterval(0, nouns.length - 1)]
}