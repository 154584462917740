import { combineReducers } from 'redux'
import navigation from './navigation'
import colors from './colors'
import leftSidebar from './left-sidebar'
import dashboard from './dashboard'
import config from './config'
import run from './run'
import hedge from './hedge'
import management from './management'
import user from './user'
import users from './user-management'
import exceptions from './ha-exceptions'

const rootReducer = combineReducers({
  navigation,
  colors,
  leftSidebar,
  dashboard,
  config,
  hedge,
  run,
  management,
  user,
  users,
  exceptions,
})

export default rootReducer
