import { client } from "utils/api"

export async function getLoanCashFlowData(params) {
  try {
    return await client.get(`/datatables/loan-cash-flow/`, { params })
  } catch (error) {
    return error
  }
}

export async function getLoanInfoData(params) {
  try {
    return await client.get(`/datatables/loan-info/`, { params })
  } catch (error) {
    return error
  }
}

export async function getYieldCurvesData(params) {
  try {
    return await client.get(`/datatables/yield-curve/`, { params })
  } catch (error) {
    return error
  }
}

export async function getDerivativeInfoData(params) {
  try {
    return await client.get(`/datatables/derivative-info/`, { params })
  } catch (error) {
    return error
  }
}

export async function getDerivativeCashFlowData(params) {
  try {
    return await client.get(`/datatables/derivative-cash-flow/`, { params })
  } catch (error) {
    return error
  }
}

export async function getMatchResultData(params) {
  try {
    return await client.get(`/datatables/match-result/`, { params })
  } catch (error) {
    return error
  }
}


export async function getAccountingEntriesData(params) {
  try {
    return await client.get(`/datatables/accounting-entries/`, { params })
  } catch (error) {
    return error
  }
}

export async function getAmortizationScheduleData(params) {
  try {
    return await client.get(`/datatables/amortization-schedule/`, { params })
  } catch (error) {
    return error
  }
}
