export function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function formatNumberDeprecated(str) {
  return str?.toLocaleString(undefined, { minimumFractionDigits: 2 })
}

export function formatNumber(str) {
  if (!str && str !== 0) return '';
  return Number(str).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}