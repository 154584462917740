import PropTypes from 'prop-types'

const BlueBox = ({ text, value, unit, Icon }) => {
  return (
    <div className="bg-blue-400 relative flex-1 text-opacity-90 rounded px-4 py-5 text-white">
      <div className="">
        <span className="text-2xl font-bold align-text-bottom mr-1 uppercase">{unit}</span>
        <span className="ml-1 text-3xl xl:text-4xl">{value}</span>
      </div>
      <p className="text-base">{text}</p>
      {Icon && (
        <div className="absolute text-white top-2 right-2 text-opacity-30">
          <Icon className="text-7xl" />
        </div>
      )}
    </div>
  )
}

BlueBox.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
}

export default BlueBox
