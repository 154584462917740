import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { PageWithText } from "../pagination";
import Loader from "components/loader";
import { getRuns } from "store/actions/runs";
import { RUN_STATUS, RUN_STATUS_TYPE } from "utils/constants/runs";
import { Link } from "react-router-dom";
import { FaInfo, FaInfoCircle } from "react-icons/fa";

const RunsTable = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [canPrevPage, setCanPrevPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const res = await getRuns({ page, page_size: pageSize });
      const data = res;
      setData(data);
      setCanNextPage(data.next);
      setCanPrevPage(data.previous);
      setLoading(false);
      setPageCount(Math.ceil(data.count / pageSize));
    };
    fetchData();
  }, [page, pageSize]);

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) setPage(page - 1);
  };
  if (loading || !data) {
    return <Loader />;
  }
  return (
    <Table
      data={data.results}
      page={page}
      pageSize={pageSize}
      setPageSize={setPageSize}
      canPrevPage={canPrevPage}
      canNextPage={canNextPage}
      prevPage={prevPage}
      nextPage={nextPage}
      loading={loading}
      pageCount={pageCount}
    />
  );
};

const Table = ({
  noPagination,
  onSizeChange,
  onSort,
  controledPageSize,
  data,
  page,
  pageSize,
  setPageSize,
  canPrevPage,
  canNextPage,
  prevPage,
  nextPage,
  pageCount,
  loading,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Run ID",
        accessor: "runId",
        Cell: ({ row }) => {
          return row.original.id;
        },
      },
      {
        Header: "Run Name",
        accessor: "name",
        Cell: ({ row }) => {
          return row.original.name;
        },
      },
      {
        Header: "Period",
        accessor: "period",
        Cell: ({ row }) => {
          if (row.original.period) {
            return row.original.period;
          }
          return "-";
        },
      },
      {
        Header: "Official",
        accessor: "isOfficial",
        Cell: ({ row }) => {
          return (
            <span className="capitalize text-xs">
              {row.original.is_official ? (
                <span className="bg-green-500 text-white px-4 py-1 rounded-full">
                  Yes
                </span>
              ) : (
                <span className="bg-red-500 text-white px-4 py-1 rounded-full">
                  No
                </span>
              )}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          if (row.original.status) {
            switch (row.original.status) {
              case RUN_STATUS.NEW:
                return (
                  <span className="text-white bg-blue-500 text-xs px-2 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              case RUN_STATUS.IN_PROGRESS:
                return (
                  <span className="text-white bg-yellow-500 text-xs px-4 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              case RUN_STATUS.COMPLETED:
                return (
                  <span className="text-white bg-green-500 text-xs px-4 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              case RUN_STATUS.EXCEPTION:
                return (
                  <span className="text-white bg-red-500 text-xs px-4 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              case RUN_STATUS.ERROR:
                return (
                  <span className="text-white bg-red-700 text-xs px-4 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              case RUN_STATUS.STOPPED:
                return (
                  <span className="text-white bg-purple-600 text-xs px-4 py-1 rounded-full">
                    {RUN_STATUS_TYPE[row.original.status]}
                  </span>
                );
              default:
                return "-";
            }
          }
          return "-";
        },
      },
      {
        Header: "Detail",
        accessor: "detail",
        Cell: ({ row }) => {
          if (row.original.id) {
            return (
              <Link to={`/runs/run/${row.original.id}`}>
                <FaInfoCircle className="text-lg" />
              </Link>
            );
          }
          return "-";
        },
      },
    ],
    []
  );

  const dataMemo = useMemo(() => data, [data]);
  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      manualPagination: true,
      manualSortBy: true,
      columns,
      data: dataMemo,
      initialState: { pageIndex: 0, hiddenColumns: ["id"] },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      if (!onSort) return;
      if (sortBy.length > 0) {
        // creating a sort string that django understands
        const sortStr = `${sortBy[0].desc ? "-" : ""}${sortBy[0].id.replace(
          /\./g,
          "__"
        )}`;
        onSort(sortStr);
      } else {
        onSort(null);
      }
    } else {
      isFirstRender.current = false;
    }
  }, [sortBy]);
  // Render the UI for your table

  return (
    <>
      <div className="table-container-outer">
        <table {...getTableProps()} className="table overflow-x-scroll block">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      {/* Add a sort direction indicator */}
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  {/* <td>test</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row items-center justify-between my-4">
        <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
          {/* {pageIndex !== 0 && <PageWithText onClick={() => gotoPage(0)}>First</PageWithText>} */}
          <PageWithText onClick={prevPage} disabled={!canPrevPage || loading}>
            Previous
          </PageWithText>

          <PageWithText onClick={nextPage} disabled={!canNextPage || loading}>
            Next
          </PageWithText>
          {/* {pageIndex !== pageCount - 1 && (
            <PageWithText onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              Last
            </PageWithText>
          )} */}
        </div>

        {noPagination || (
          <>
            <span>
              Page{" "}
              <b>
                {page} of {pageCount}
              </b>{" "}
            </span>
            <select
              className="form-select text-sm bg-white dark:bg-gray-800 dark:border-gray-800 outline-none shadow-none focus:shadow-none"
              value={controledPageSize || pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50, 100].map((ps) => (
                <option key={ps} value={ps}>
                  Show {ps}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
      {/* <pre>
        {JSON.stringify(
          data.filter((item, index) =>
            Object.keys(selectedRowIds)
              .map((i) => parseInt(i, 10))
              .includes(index)
          ),
          null,
          2
        )}
      </pre> */}
    </>
  );
};

export default RunsTable;
