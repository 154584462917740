import React, { useEffect } from "react"
import Title from "components/left-sidebar/title"
import Item from "components/left-sidebar/item"
import Logo from "components/left-sidebar/logo"
import {
  FaHome,
  FaInfoCircle,
  FaPlayCircle,
  FaTable,
  FaArchive,
  FaBook,
  FaDownload,
  FaCalendarAlt,
} from "react-icons/fa"
import { FiSettings } from "react-icons/fi"
import { connect, useDispatch } from "react-redux"
import { BiError } from "react-icons/bi"
import { setRun, initializeRun, setPeriod } from "store/actions/runs"
import { HiDatabase } from "react-icons/hi"
import DropdownList from "../dropdown-list"

const getSidebarItems = () => {
  const allItems = [
    {
      items: [
        {
          url: "/hedges/latest",
          icon: <FaHome size={20} />,
          title: "Hedge Summary",
          items: [],
        },
        {
          url: "/hedges/hedged-item",
          icon: <FaInfoCircle size={20} />,
          title: "Hedged Item",
          items: [],
        },
        {
          url: "/hedges/hedging-instrument",
          icon: <FaInfoCircle size={20} />,
          title: "Hedging Instrument",
          items: [],
        },
        {
          url: "/hedges/data-tables",
          icon: <FaTable size={20} />,
          title: "Data Tables",
          items: [],
        },
        {
          url: "/runs/create-run",
          icon: <FaPlayCircle size={20} />,
          title: "Run FVPH",
          items: [],
        },
        {
          url: "/runs/logs",
          icon: <FaArchive size={20} />,
          title: "Run Logs",
          items: [],
        },
        {
          url: "/hedges/database-logs",
          icon: <HiDatabase size={20} />,
          title: "Database Logs",
          items: [],
        },
        {
          url: "/hedges/settings",
          icon: <FiSettings size={20} />,
          title: "Settings",
          items: [],
        },
        {
          url: "/exceptions",
          icon: <BiError size={20} />,
          title: "Exceptions",
          items: [],
        },
      ],
    },
  ]

  return allItems
}
const LeftSidebar = ({ runs, selectedRun, selectedPeriod }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeRun())
  }, [])

  const buttonClickHandler = (setState) => (hideList) => (item) => {
    hideList()
    dispatch(setState(item.id))
  }
  const periodClickHandler = (setState) => (hideList) => (item) => {
    hideList()
    dispatch(setState(item.id))
  }
  return (
    <div className="left-sidebar left-sidebar-1">
      <Logo />
      <Title>Period</Title>
      <DropdownList
        selected={selectedPeriod}
        items={runs?.selected?.periods.map((item) => ({
          id: item,
          text: item,
        }))}
        buttonClickHandler={periodClickHandler(setPeriod)}
      />
      <Title>Run</Title>
      <DropdownList
        selected={selectedRun?.name}
        items={runs?.data?.map((item) => ({ id: item.id, text: item.name }))}
        buttonClickHandler={buttonClickHandler(setRun)}
      />
      
      {getSidebarItems().map((menu, i) => (
        // TODO HB-213: Change maps
        <React.Fragment key={i}>
          <Title>{menu.title}</Title>
          <ul>
            {menu.items.map((l0, a) => (
              <li key={a} className="l0">
                <Item {...l0} />
                <ul>
                  {l0.items.map((l1, b) => (
                    <li key={b} className="l1">
                      <Item {...l1} />
                      <ul>
                        {l1.items.map((l2, c) => (
                          <li key={c} className="l2">
                            <Item {...l2} />
                            <ul>
                              {l2.items.map((l3, d) => (
                                <li key={d} className="l3">
                                  <Item {...l3} />
                                  <ul>
                                    {l3.items.map((l4, e) => (
                                      <li key={e} className="l4">
                                        <Item {...l4} />
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}

export default connect(({ run }) => ({
  runs: run.runs,
  selectedRun: run.runs.selected,
  selectedPeriod: run.runs.selected?.period,
}))(LeftSidebar)
