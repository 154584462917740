const FormElement = ({ label, error, children, hint }) => {
  return (
    <div className="form-element">
      {label && <div className="form-label">{label}</div>}
      {children}
      {error && <div className="form-error">{error}</div>}
      {hint && <span className="form-hint">{hint}</span>}
    </div>
  )
}

export default FormElement
