import { client } from 'utils/api'
import {
  EXCEPTIONS_FETCHING,
  EXCEPTIONS_SET,
  EXCEPTIONS_FILTERS,
  EXCEPTIONS_PREV_PAGE,
  EXCEPTIONS_FETCHING_ERROR,
  EXCEPTIONS_NEXT_PAGE,
  EXCEPTIONS_CLEAR,
} from './types'

export function setExceptions(filters) {
    return async (dispatch) => {
      try {
        dispatch({ type: EXCEPTIONS_FETCHING })
        const response = await client.get('/exceptions/', { params: filters })
        dispatch({
          type: EXCEPTIONS_SET,
          payload: response.data,
        })
      } catch (err) {
        if (err.response?.status === 404 && filters.page > 1) {
          dispatch({ type: EXCEPTIONS_PREV_PAGE })
        } else {
          dispatch({ type: EXCEPTIONS_FETCHING_ERROR })
        }
      }
    }
  }

  export function updateExceptionsFilters(newFilters) {
    return { type:  EXCEPTIONS_FILTERS, payload: newFilters }
  }
  
  export function setExceptionsSort(ordering) {
    return { type: EXCEPTIONS_FILTERS, payload: {ordering } }
  }

  export function nextPageExceptions() {
    return { type: EXCEPTIONS_NEXT_PAGE }
  }
  
  export function prevPageExceptions() {
    return { type: EXCEPTIONS_PREV_PAGE }
  }
  
  export function clearExceptions() {
    return { type: EXCEPTIONS_CLEAR }
  }
  