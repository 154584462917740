import {
    RUN_INITIALIZE,
    RUN_SET,
    PERIOD_SET,
    RUN_LOADING,
    RUN_CLEAN,
    RUN_ERROR,
    RUN_INITIALIZE_ERROR,
  } from '../../actions/runs/type'
  
  const initialState = {
    runs: {
      selected: null, //latest run
      isLoading: true,
      data: null,  //tarihe göre tüm run'lar
    },
    periods: {
      data: null, //periods
      selected: null, //latest period
    },
  }
  
  export default function run(state = initialState, action) {
    switch (action.type) {
      case RUN_LOADING: {
        const newState = { ...state }
        newState.runs.isLoading = action.isLoading
        return newState
      }
      case RUN_INITIALIZE: {
        return { ...state, ...action.payload, error: null }
      }
      case RUN_INITIALIZE_ERROR: {
        return { ...state, runs: { ...initialState.runs, isLoading: false }, error: action.error }
      }
      case RUN_ERROR: {
        return { ...state, runs: { ...state.runs, isLoading: false }, error: action.error }
      }
      case RUN_SET: {
        action.payload.runs.data = state.runs.data
        return { ...state, ...action.payload }
      }
      case PERIOD_SET: {
        return { ...state, ...action.payload }
      }
      case RUN_CLEAN: {
        return initialState
      }
      default:
        return state
    }
  }
  