export const AUTH_STORAGE_KEY = 'user'
export const AUTH_EXCEPT_ROUTES = [
  '/activation',
  '/password-reset',
  '/password-reset-confirm',
  '/forgot-password',
]

export const PROSPECTIVE_UPPER_LIMIT_DEFAULT = 1.245
export const PROSPECTIVE_LOWER_LIMIT_DEFAULT = 0.805
export const PROSPECTIVE_MTM_DELTA_MULTIPLIER = 0.99
