import PropTypes from "prop-types"
import React from "react"
import Portal from "components/portal"
import { Button } from "components/buttons"

const Modal = React.forwardRef(
  (
    {
      title,
      icon,
      buttonTitle,
      body,
      onButtonClick,
      hide,
      hideButtonTitle = 'Cancel',
      onHideButtonClick,
    },
    ref
  ) => {
    return (
      <Portal selector="#portal">
        <div className="modal-backdrop fade-in z-50" />
        <div className="modal show">
          <div className="relative w-[600px] lg:my-4 mx-auto max-w-lg" ref={ref}>
            <div className="bg-white text-gray-900 border-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-700 border-0 rounded-lg shadow-lg relative flex flex-col w-full items-center justify-center outline-none">
              <div className="relative p-4 w-full text-center">
                {icon}
                <div className="flex flex-col w-full mb-4">
                  <div className="text-lg mb-2 font-bold">{title}</div>
                  {body}
                </div>
                <div className="flex flex-row justify-center">
                <Button
                  className="mb-2"
                  type="button"
                  onClick={() => {
                    hide()
                    onHideButtonClick?.()
                  }}
                  rounded
                  wFull
                  flat
                >
                  {hideButtonTitle}
                </Button>
                {buttonTitle && (
                  <Button
                    type="button"
                    onClick={() => {
                      onButtonClick()
                      hide()
                    }}
                    rounded
                    wFull
                  >
                    {buttonTitle}
                  </Button>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    )
  }
)

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  hideButtonTitle: PropTypes.string.isRequired,
}

export default Modal
