import "./css/tailwind.css"
import "./css/main.css"
import "./css/layouts/layout-1.css"
import "./css/animate.css"
import "./css/components/buttons.css"
import "./css/components/datepicker.css"
import "./css/components/dropdowns.css"
import "./css/components/forms.css"
import "./css/components/left-sidebar-1/styles-lg.css"
import "./css/components/left-sidebar-1/styles-sm.css"
import "./css/components/modals.css"
import "./css/components/navbar.css"
import "./css/components/nprogress.css"
import "./css/components/recharts.css"
import "./css/components/sliders.css"
import "./css/components/steps.css"
import "./css/components/tables.css"
import "./css/components/tabs.css"
import "./css/components/user-widgets/widget-2.css"
import "./css/components/user-widgets/widget-4.css"

import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Provider } from "react-redux"
import store from "store"
import Login from "pages/login"
import InitializerLayout from "layouts/initializer"
import HedgeLayout from "layouts/hedge"
import LatestHedgePage from "pages/hedges/latest"
import HedgedItemPage from "pages/hedges/hedged-item"
import HedgingInstrumentPage from "pages/hedges/hedging-instrument"
import DataTablesPage from "pages/hedges/data-tables"
import CreateRunOrdersForm from "pages/runs/create-run"
import RunDetail from "pages/runs/run-detail"
import ExceptionsPage from "pages/ha-exceptions/ha-exceptions"
import RunLogs from "pages/runs/run-logs"

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<InitializerLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="hedges/*" element={<HedgeLayout />}>
              <Route path="latest" element={<LatestHedgePage />} />
              <Route path="hedged-item" element={<HedgedItemPage />} />
              <Route path="documents" element={<HedgedItemPage />} />
              <Route path="accounting-entries" element={<HedgedItemPage />} />
              <Route
                path="hedging-instrument"
                element={<HedgingInstrumentPage />}
              />
              <Route path="data-tables" element={<DataTablesPage />} />
            </Route>
            <Route path="runs/*" element={<HedgeLayout />}>
              <Route path="logs" element={<RunLogs />} />
              <Route path="create-run" element={<CreateRunOrdersForm />} />
              <Route path="run/:runId/" element={<RunDetail />} />
            </Route>
            <Route path="change-password" element={<p>Change password</p>} />
            <Route path="exceptions" element={<HedgeLayout />}>
              <Route index element={<ExceptionsPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}
