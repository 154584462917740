export function setItemLocal(name, data) {
  window.localStorage.setItem(name, JSON.stringify(data))
}

export function getItemLocal(name) {
  return JSON.parse(window.localStorage.getItem(name))
}

export function updateItemLocal(name, newObj) {
  const oldItem = getItemLocal(name)
  window.localStorage.setItem(name, JSON.stringify({ ...oldItem, ...newObj }))
}

export function removeItemLocal(name) {
  window.localStorage.removeItem(name)
}
