import * as yup from 'yup'
import { phoneRegex } from '../regex'

export const requiredString = yup.string().trim().required()
export const minString = (n) => yup.string().min(n).required()
export const phoneString = yup.lazy((val) =>
  !val ? yup.string() : yup.string().matches(phoneRegex, 'Invalid phone number.')
)
export const requiredStringEquals = (str) => yup.string().required().oneOf([str])
export const requiredEmail = yup.string().email().required()

export const requiredNumber = yup.number().integer().required()

export const minArray = (n) => yup.array().min(n).required()

export const requiredDecimal = yup.number().required()

export const timeLimitSchema = yup.number().min(2, 'Time limit can be minimum 2 minutes.').max(20, 'Time limit can be maximum 20 minutes.')

export const prospectiveLowerLimitSchema = upperLimit => yup.number().min(0.805, 'Prospective Lower Limit can not be lower than 0,805.').max(upperLimit - 0.005)

export const prospectiveUpperLimitSchema = lowerLimit => yup.number().max(1.245, 'Prospective Upper Limit can not be higher than 1,245.').min(lowerLimit + 0.005)

export const prospectiveMTMDeltaMultiplierSchema = yup.number().min(0.001, 'Prospective MTM Delta Multiplier can not be less than 0.001.').max(10, 'Prospective MTM Delta Multiplier can not be greater than 10.')