import { client } from "utils/api";
import { PERIOD_SET, RUN_INITIALIZE, RUN_INITIALIZE_ERROR, RUN_LOADING, RUN_SET } from "./type";

export async function getRuns(params) {
  try {
    const response = await client.get("/runs/", { params });
    return response.data;
  } catch (ex) {
  }
}

function getInitialRunPayload(responseData) {
  const { runs, display_run} = responseData
  const payload = {
    runs: {
      isLoading: false,
      data: runs,
    },
  }
  payload.runs.selected = runs.find((h) => h.id === display_run.id)
  payload.periods = {
    data: display_run.periods,
    selected: display_run.period
  }
  return payload
}

export function initializeRun() {
  return async (dispatch) => {
    try {
      const response = await client.get(`/runs/initial/`)
      const payload = getInitialRunPayload(response.data)
      dispatch({
        type: RUN_INITIALIZE,
        payload,
      })
    } catch (err) {
      const error = {
        status: err.response.status,
      }
      dispatch({ type: RUN_INITIALIZE_ERROR, error })
    }
  }
}

export function setRun(runId) {
  return async (dispatch) => {
    dispatch({ type: RUN_LOADING, isLoading: true })
    try {
      const response = await client.get(`/runs/${runId}/`)
      const { data } = response
      const payload = {
        runs: {
          isLoading: false,
          selected: data,
        },
      }

      dispatch({
        type: RUN_SET,
        payload,
      })
    } catch (err) {}
  }
}

export function setPeriod(period) {
  return async (dispatch) => {
    dispatch({ type: RUN_LOADING, isLoading: true })
    try {
      const response = await client.get(`/runs/list/?period=${period}`)
      const { runs, display_run } = response.data
      const payload = {
        runs: {
          isLoading: false,
          data: runs,
        },
      }
      payload.runs.selected = runs.find((h) => h.id === display_run.id)
      payload.periods = {
        selected: display_run.period,
      }

      dispatch({
        type: PERIOD_SET,
        payload,
      })
    } catch (err) {}
  }
}
