import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import ManagementLayout from "layouts/management";
import Datatable from "components/datatable/pagination";
import SectionTitle from "components/section-title";

import Loader from "components/loader";
import { useDispatch } from "react-redux";
import Widget from "components/widget";

import {
  nextPageExceptions,
  prevPageExceptions,
  updateExceptionsFilters,
  setExceptionsSort,
} from "store/actions/ha-exceptions";

const ManagementSubLayout = ({ data, mainColumns, filters }) => {
  const dispatch = useDispatch();

  const columns = useMemo(() => [...mainColumns], []);

  const dataArray = useMemo(() => data.data.results, [data]);
  const nextPage = () => dispatch(nextPageExceptions());
  const prevPage = () => dispatch(prevPageExceptions());
  const [deneme, setDeneme] = useState("created_at");

  const onSort = (sortBy) => {
    if (sortBy.filter((data) => data.includes("-"))) {
      dispatch(setExceptionsSort(sortBy));
    } else {
      dispatch(setExceptionsSort(sortBy));
    }
  };
  return (
    <>
      <SectionTitle title="" subtitle="Exceptions" />
      <Widget description="">
        {data.isLoading ? <Loader /> : null}
        {dataArray?.length && (
          <div className={`${data.isLoading ? "hidden" : ""}`}>
            <Datatable
              columns={columns}
              data={dataArray}
              canPrevPage={data.data.previous}
              canNextPage={data.data.next}
              nextPage={nextPage}
              prevPage={prevPage}
              onSort={(sortBy) => dispatch(setExceptionsSort(sortBy))}
              controledPageSize={data.filters.page_size}
              onSizeChange={(page_size) =>
                dispatch(updateExceptionsFilters({ page_size }))
              }
              pageCount={Math.ceil(data.data.count / data.filters.page_size)}
              pageIndex={filters.page}
            />
          </div>
        )}
      </Widget>
    </>
  );
};

export default ManagementSubLayout;
