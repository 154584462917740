import React, { useState, useEffect, useRef } from 'react'
import List from './list'

const Dropdown = ({ selected, items, buttonClickHandler }) => {
  const [hidden, setHidden] = useState(true)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  const reverseHidden = () => {
    setHidden(!hidden)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      reverseHidden()
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    reverseHidden()
  }

  return (
    <div className="flex relative">
      {items?.length > 0 && (
        <button
          type="button"
          ref={buttonRef}
          onClick={handleDropdownClick}
          className="flex items-center justify-center h-10 w-12"
        >
          <span className="absolute bg-gray-800 hover:bg-gray-700 left-4 w-4/5 p-2 text-left rounded-sm">
            {selected}
          </span>
        </button>
      )}
      <div
        ref={dropdownRef}
        className={`dropdown absolute top-0 left-4 mt-10 ${hidden ? '' : 'open'}`}
      >
        <div className="dropdown-content w-36 bottom-start">
          <List items={items} buttonClickHandler={buttonClickHandler(reverseHidden)} />
        </div>
      </div>
    </div>
  )
}

export default Dropdown
